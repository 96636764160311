import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_button = _resolveComponent("modal-button")!

  return (_openBlock(), _createBlock(_component_modal_button, _mergeProps({ ..._ctx.$props, ..._ctx.$attrs }, {
    ref: "modalButtonRef",
    onSubmit: _ctx.patch
  }), {
    button: _withCtx(() => _cache[0] || (_cache[0] = [
      _createTextVNode(" 실행 ")
    ])),
    _: 1
  }, 16, ["onSubmit"]))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_statistic = _resolveComponent("a-statistic")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_bulk_action_modal = _resolveComponent("bulk-action-modal")!
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_openBlock(), _createBlock(_component_ResourceTable, {
    title: "사용자 피드백",
    uri: "/api/user-feedback",
    "filter-spec": _ctx.filterSpec,
    "row-selection": {
            selectedRowKeys: _ctx.selectedRowKeys,
            onChange: _ctx.onSelectChange,
        },
    scroll: { x: true },
    columns: _ctx.columns,
    exportable: "",
    "load-on-mount": ""
  }, {
    summary: _withCtx(({ search }) => [
      _createVNode(_component_a_card, {
        title: _ctx.summary.title
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summary.values, (stat) => {
                return (_openBlock(), _createBlock(_component_a_statistic, {
                  key: stat.label,
                  value: stat.value,
                  style: {"margin-right":"20px","overflow":"scroll"}
                }, {
                  title: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "link",
                      onClick: 
                                    () => {
                                        _ctx.setProductIdFilter(stat.productId)
                                        search()
                                    }
                                
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" ID: " + _toDisplayString(stat.productId), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createTextVNode(" " + _toDisplayString(stat.label), 1)
                  ]),
                  _: 2
                }, 1032, ["value"]))
              }), 128))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1032, ["title"])
    ]),
    actions: _withCtx(({ uri, search }) => [
      _createVNode(_component_a_button, {
        style: {"color":"black"},
        loading: _ctx.loading,
        disabled: ""
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("처리상태 변경")
        ])),
        _: 1
      }, 8, ["loading"]),
      _createVNode(_component_a_button, {
        onClick: () => _ctx.updateStatus('OPEN', uri, search),
        disabled: _ctx.any('OPEN')
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("처리 전 ")
        ])),
        _: 2
      }, 1032, ["onClick", "disabled"]),
      _createVNode(_component_a_button, {
        onClick: () => _ctx.updateStatus('PENDING', uri, search),
        disabled: _ctx.any('PENDING')
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("처리 중")
        ])),
        _: 2
      }, 1032, ["onClick", "disabled"]),
      _createVNode(_component_a_button, {
        onClick: () => _ctx.updateStatus('CLOSED', uri, search),
        disabled: _ctx.any('CLOSED')
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("처리 됨")
        ])),
        _: 2
      }, 1032, ["onClick", "disabled"]),
      _createVNode(_component_a_button, {
        onClick: () => _ctx.updateStatus('QC', uri, search),
        disabled: _ctx.any('QC')
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode("QC 필요")
        ])),
        _: 2
      }, 1032, ["onClick", "disabled"]),
      _createVNode(_component_a_button, {
        onClick: () => _ctx.updateStatus('QC_DONE', uri, search),
        disabled: _ctx.any('QC_DONE')
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode("QC 완료")
        ])),
        _: 2
      }, 1032, ["onClick", "disabled"]),
      _createVNode(_component_bulk_action_modal, {
        class: "modal__button",
        onAfterSubmit: ($event: any) => (_ctx.resetSelectedRows(search)),
        "resource-ids": _ctx.selectedRowKeys,
        uri: uri,
        "input-spec": _ctx.stashedActionSpec,
        label: "숨김",
        disabled: _ctx.any('STASHED')
      }, null, 8, ["onAfterSubmit", "resource-ids", "uri", "input-spec", "disabled"]),
      _createVNode(_component_bulk_action_modal, {
        class: "modal__button",
        onAfterSubmit: ($event: any) => (_ctx.resetSelectedRows(search)),
        "resource-ids": _ctx.selectedRowKeys,
        uri: uri,
        "input-spec": _ctx.productExistsActionSpec,
        label: "상품 기등록",
        disabled: _ctx.any('PRODUCT_EXISTS')
      }, null, 8, ["onAfterSubmit", "resource-ids", "uri", "input-spec", "disabled"]),
      _createVNode(_component_bulk_action_modal, {
        class: "modal__button",
        onAfterSubmit: ($event: any) => (_ctx.resetSelectedRows(search)),
        "resource-ids": _ctx.selectedRowKeys,
        uri: uri,
        "input-spec": _ctx.unresolvedActionSpec,
        label: "처리 불가",
        disabled: _ctx.any('UNRESOLVED')
      }, null, 8, ["onAfterSubmit", "resource-ids", "uri", "input-spec", "disabled"]),
      _createVNode(_component_bulk_action_modal, {
        class: "modal__button",
        onAfterSubmit: ($event: any) => (_ctx.resetSelectedRows(search)),
        "resource-ids": _ctx.selectedRowKeys,
        uri: `${uri}/reply/bulk`,
        "input-spec": _ctx.replyBulkSpec,
        label: "단체 답변",
        disabled: _ctx.any('CLOSED')
      }, null, 8, ["onAfterSubmit", "resource-ids", "uri", "input-spec", "disabled"])
    ]),
    _: 1
  }, 8, ["filter-spec", "row-selection", "columns"]))
}
<template>
    <a-button
        v-if="showButton"
        @click="showModal"
        :disabled="disabled"
        v-bind="{ ...$props, ...$attrs }"
    >
        <template #icon>
            <slot name="icon" />
            {{ label }}
        </template>
        <slot />
        <div></div>
    </a-button>
    <slot v-else name="icon" :showModal="showModal" :disabled="disabled" />
    <a-modal
        :wrap-class-name="(expand && 'full-modal') || 'basic-modal'"
        :title="title"
        v-model:visible="visible"
        :footer="null"
        :width="(expand && '100%') || '80em'"
        @cancel="resetForm"
    >
        <span style="float: right">
            <a-button v-if="!expand" @click="toggleExpand">
                <template #icon>
                    <fullscreen-outlined />
                </template>
            </a-button>
            <a-button v-else-if="expand" @click="toggleExpand">
                <template #icon>
                    <fullscreen-exit-outlined />
                </template>
            </a-button>
        </span>
        <slot
            name="header"
            :record="record"
            :resource-ids="resourceIds"
            :resource-id="resourceId"
            :form="form"
        />
        <a-skeleton v-if="loading" />
        <a-form v-else ref="formRef" :model="form" :rules="rules">
            <InputMapper
                v-for="(key, index) in formKeys"
                :key="index"
                :input-spec="formSpec[key]"
                :form="form"
                :prop="key"
                :resource-id="resourceId"
            />
            <a-form-item class="footer__row">
                <a-affix :offset-bottom="100" style="margin-right: 50px">
                    <slot
                        name="beforeButton"
                        :record="record"
                        :resourceId="resourceId"
                        :form="form"
                        :onSubmit="onSubmit"
                    />
                    <slot
                        name="submit"
                        :record="record"
                        :resourceId="resourceId"
                        :form="form"
                        :onSubmit="onSubmit"
                    />
                    <a-button
                        v-if="!$slots.submit"
                        type="primary"
                        @click.prevent="onSubmit"
                        html-type="submit"
                        :loading="loading"
                    >
                        <slot name="button" />
                    </a-button>
                </a-affix>
            </a-form-item>
        </a-form>
        <slot
            name="footer"
            :record="record"
            :resource-ids="resourceIds"
            :resource-id="resourceId"
            :form="form"
        />
    </a-modal>
    <FailResultModal
        ref="failResultModal"
        :title="`${title} 실패`"
        :error-message="errorMessage"
    />
</template>

<style lang="scss">
.footer__row {
    .ant-form-item-control {
        flex-direction: row-reverse;
    }
}

.modal__button {
    &.ant-btn {
        background-color: #41c1b2;
        color: white;
        &:hover,
        &:focus {
            background-color: #30f2db;
            color: #6c2bf2;
            border-color: #6c2bf2;
        }
        &:disabled {
            background-color: #d9d9d9;
            color: #8c8c8c;
            border-color: #8c8c8c;
        }
    }
}

.basic-modal {
    .ant-modal {
        max-width: 80em;
    }
}

.full-modal {
    .ant-modal {
        max-width: 100%;
        top: 0;
        padding-bottom: 0;
        margin: 0;
    }
    .ant-modal-content {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .ant-modal-body {
        flex: 1;
    }

    .ant-select,
    input,
    textarea,
    .ant-input-affix-wrapper-textarea-with-clear-btn {
        width: 50vw !important;
        max-width: 800px !important;
    }
}
</style>

<script lang="ts">
import InputMapper from '@/components/InputMapper.vue'
import FailResultModal from '@/components/modal/FailResultModal.vue'
import globalFormState from '@/components/globalFormState'
import { FormInstance } from 'ant-design-vue'
import { defineComponent, ref } from 'vue'
import {
    FullscreenOutlined,
    FullscreenExitOutlined,
} from '@ant-design/icons-vue'

export default defineComponent({
    name: 'ModalButton',
    components: {
        InputMapper,
        FailResultModal,
        FullscreenOutlined,
        FullscreenExitOutlined,
    },
    props: {
        uri: {
            type: String,
            required: true,
        },
        resourceIds: {
            type: Array,
        },
        resourceId: {
            type: [Number, String],
        },
        record: {
            type: Object,
        },
        title: {
            type: String,
            default: '',
        },
        label: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        rules: {
            type: Object,
        },
        icon: {
            type: String,
        },
        inputSpec: {
            type: Object,
            default: () => ({}),
        },
        showButton: {
            type: Boolean,
            default: true,
        },
        shape: {
            type: String,
        },
    },
    setup(_, { expose }) {
        const failResultModal = ref()
        const formRef = ref<FormInstance>()
        const loading = ref(false)
        const errorMessage = ref('')
        const visible = ref(false)
        const expand = ref(false)

        const toggleLoading = () => {
            loading.value = !loading.value
        }
        const setErrorMessage = (message: string) => {
            errorMessage.value = message
        }
        const toggleVisible = () => {
            visible.value = !visible.value
        }
        const toggleExpand = () => {
            expand.value = !expand.value
        }
        const alertFail = () => {
            failResultModal.value.open()
        }

        expose({ toggleLoading, setErrorMessage, toggleVisible, alertFail })

        return {
            ...globalFormState,
            expand,
            formRef,
            failResultModal,
            loading,
            errorMessage,
            visible,
            toggleLoading,
            setErrorMessage,
            toggleVisible,
            toggleExpand,
            alertFail,
        }
    },
    emits: ['submit', 'afterShow', 'afterSubmit', 'resetForm'],
    methods: {
        onSubmit() {
            return this.formRef
                ?.validate()
                .then(() => {
                    return this.$emit('submit')
                })
                .then(() => {
                    return this.$emit('afterSubmit')
                })
                .catch((error: Error) => {
                    this.setErrorMessage(
                        '입력 유효성 검증에 실패했습니다. 입력을 확인해주세요.'
                    )
                    this.alertFail()
                    throw error
                })
        },
        showModal() {
            this.$emit('resetForm')
            this.toggleVisible()
            if (this.visible === true) {
                this.overwriteFormSpec(this.inputSpec)
                this.$emit('afterShow')
            }
        },
    },
})
</script>

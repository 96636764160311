<template>
    <resource-table
        title="푸드렌즈 음식 매칭"
        uri="/api/food-lens-map"
        :columns="columns"
        :filter-spec="filterSpec"
        :update-spec="updateSpec"
        :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
        }"
        load-on-mount
    >
        <template #actions="{ uri, search }">
            <bulk-action-modal
                class="modal__button"
                @after-submit="search"
                :resource-ids="selectedRowKeys"
                :uri="`${uri}/resolve`"
                :input-spec="resolvedSpec"
                label="포괄처리"
                :disabled="selectedRowKeys.length === 0"
            />
        </template>
    </resource-table>
</template>

<script lang="ts" setup>
import { InputComponents } from '@/components/InputMapper.vue'
import ResourceTable from '@/components/ResourceTable.vue'
import BulkActionModal from '@/components/modal/BulkActionModal.vue'
import { formatBoolean } from '@/util/formmater'
import { ref } from 'vue'

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        width: 60,
        fixed: 'left',
    },
    {
        title: '푸드렌즈 음식 ID',
        dataIndex: 'foodLensId',
        sorter: true,
    },
    {
        title: '푸드렌즈 음식 이름',
        dataIndex: 'foodLensName',
        sorter: true,
    },
    {
        title: '음식 ID',
        dataIndex: 'foodId',
    },
    {
        title: '음식 이름',
        dataIndex: 'foodName',
    },
    {
        title: '편집 거리',
        dataIndex: 'levenshteinDistance',
        sorter: true,
    },
    {
        title: '완료여부',
        dataIndex: 'resolved',
        customRender: formatBoolean,
        sorter: true,
    },
]

const updateSpec = ref({
    foodId: {
        label: '음식 ID',
        value: null,
        component: InputComponents.FoodInput,
    },
    resolved: {
        label: '완료여부',
        value: null,
        component: InputComponents.BooleanInput,
    },
})

const filterSpec = ref({
    id: {
        label: 'ID',
        value: null,
        component: InputComponents.AInputNumber,
    },
    foodLensId: {
        label: '푸드렌즈 음식 ID',
        value: null,
        component: InputComponents.AInputNumber,
    },
    foodLensName: {
        label: '푸드렌즈 음식 이름',
        value: null,
        labelCol: 8,
    },
    foodId: {
        label: '음식 ID',
        value: null,
        component: InputComponents.FoodInput,
    },
    levenshteinDistance: {
        label: '편집 거리',
        value: null,
        component: InputComponents.AInputNumber,
    },
    resolved: {
        label: '완료여부',
        value: null,
        component: InputComponents.BooleanInput,
    },
})

const selectedRowKeys = ref<Array<number>>([])

const onSelectChange = (key: Array<number>) => {
    selectedRowKeys.value = key
}

const resolvedSpec = ref({
    foodId: {
        label: '음식 ID',
        value: null,
        component: InputComponents.FoodInput,
    },
    resolved: {
        label: '완료여부',
        value: null,
        component: InputComponents.BooleanInput,
    },
})
</script>

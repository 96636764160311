import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_bulk_action_modal = _resolveComponent("bulk-action-modal")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_ResourceTable = _resolveComponent("ResourceTable")!
  const _component_event_user_verification_modal = _resolveComponent("event-user-verification-modal")!
  const _component_send_crm_modal = _resolveComponent("send-crm-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ResourceTable, {
      ref: "eventUserTable",
      title: "필라이즈 이벤트 참가자",
      uri: "/api/v1/event-users",
      "filter-spec": _ctx.filterSpec,
      "create-rules": _ctx.rules,
      "update-rules": _ctx.rules,
      scroll: { x: 1800, y: 650 },
      columns: _ctx.columns,
      "row-selection": {
            selectedRowKeys: _ctx.selectedRowKeys,
            onChange: _ctx.onSelectChange,
        },
      exportable: "",
      "load-on-mount": "",
      method: "POST"
    }, {
      actions: _withCtx(({ uri, search }) => [
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _cache[4] || (_cache[4] = _createElementVNode("h3", null, "CRM 발송", -1)),
                _createVNode(_component_a_button, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openCrmModal('sms')))
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("문자")
                  ])),
                  _: 1
                }),
                _createVNode(_component_a_button, {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openCrmModal('bizm')))
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("알림톡")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _cache[5] || (_cache[5] = _createElementVNode("h3", null, "이벤트 인증 상태 값", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.eventVerifications, (item, index) => {
                  return (_openBlock(), _createBlock(_component_a_button, {
                    key: index,
                    onClick: () => _ctx.updateVerification(item.value, search)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.label), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _cache[8] || (_cache[8] = _createElementVNode("h3", null, "이벤트 관리", -1)),
                _createVNode(_component_a_button, {
                  onClick: () => _ctx.promoteEventUser('true', uri, search)
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode(" 당첨 처리 ")
                  ])),
                  _: 2
                }, 1032, ["onClick"]),
                _createVNode(_component_a_button, {
                  onClick: () => _ctx.promoteEventUser('false', uri, search)
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode(" 당첨 취소 ")
                  ])),
                  _: 2
                }, 1032, ["onClick"]),
                _createVNode(_component_bulk_action_modal, {
                  class: "modal__button",
                  onAfterSubmit: ($event: any) => (_ctx.resetSelectedRows(search)),
                  "resource-ids": _ctx.selectedRowKeys,
                  uri: `${uri}/notify`,
                  "input-spec": _ctx.notificationSpec,
                  rules: _ctx.notificationRules,
                  label: "알림 센터 발송",
                  disabled: !_ctx.selected() || _ctx.hasNotified()
                }, null, 8, ["onAfterSubmit", "resource-ids", "uri", "input-spec", "rules", "disabled"]),
                _createVNode(_component_bulk_action_modal, {
                  class: "modal__button",
                  onAfterSubmit: ($event: any) => (_ctx.resetSelectedRows(search)),
                  "resource-ids": _ctx.selectedRowKeys,
                  uri: `${uri}/push`,
                  "input-spec": _ctx.pushSpec,
                  rules: _ctx.notificationRules,
                  label: "App push 발송",
                  disabled: !_ctx.selected()
                }, null, 8, ["onAfterSubmit", "resource-ids", "uri", "input-spec", "rules", "disabled"])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024)
      ]),
      customAction: _withCtx(({ record }) => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.proofImageUrl, (url, index) => {
          return (_openBlock(), _createBlock(_component_a_image, {
            src: url,
            key: index,
            preview: false,
            onClick: ($event: any) => (_ctx.showModal(record, url))
          }, null, 8, ["src", "onClick"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["filter-spec", "create-rules", "update-rules", "columns", "row-selection"]),
    _createVNode(_component_event_user_verification_modal, {
      "is-show-modal-props": _ctx.isShowModal,
      "image-url-props": _ctx.imageUrl,
      "id-props": _ctx.id,
      "event-id-props": _ctx.eventId,
      "event-user-id-props": _ctx.eventUserId,
      "event-user-name-props": _ctx.eventUserName,
      "event-user-verification-status-props": _ctx.eventUserVerificationStatus,
      "event-user-table-ref": _ctx.eventUserTable,
      "onModal:close": _ctx.closeModal
    }, null, 8, ["is-show-modal-props", "image-url-props", "id-props", "event-id-props", "event-user-id-props", "event-user-name-props", "event-user-verification-status-props", "event-user-table-ref", "onModal:close"]),
    _createVNode(_component_send_crm_modal, {
      "is-send-one-props": false,
      "type-props": _ctx.crmType,
      "label-props": _ctx.crmLabel,
      "has-custom-button": true,
      "is-open-props": _ctx.isCrmModalOpen,
      "event-user-list-props": _ctx.crmEventUserList,
      "event-user-id-list-props": _ctx.crmEventUserIdList,
      "event-user-props": _ctx.crmEventUser,
      "onModal:close": _ctx.closeCrmModal,
      "onModal:open": _ctx.openCrmModal
    }, null, 8, ["type-props", "label-props", "is-open-props", "event-user-list-props", "event-user-id-list-props", "event-user-props", "onModal:close", "onModal:open"])
  ], 64))
}
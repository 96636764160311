<template>
    <ResourceTable
        title="제품"
        uri="/api/products"
        :filter-spec="filterSpec"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :scroll="{ x: 2800, y: 650 }"
        :columns="columns"
        :create-rules="rules"
        :update-rules="rules"
        exportable
        deletable
        :row-selection="rowSelection"
        :rowDeletable="rowDeletable"
    >
        <template #actions="{ uri }">
            <CreateFormModal
                title="병합"
                label="병합"
                :uri="`${uri}/merge`"
                :input-spec="mergingSpec"
                :rules="mergingRules"
            />
            <bulk-action-modal
                class="modal__button"
                :resource-ids="rowSelection.selectedRowKeys"
                :uri="`${uri}/consumer-guide`"
                :input-spec="consumerGuideSpec"
                title="연구소 팁 입력"
                label="연구소 팁 입력"
            />
        </template>
    </ResourceTable>
</template>

<script lang="ts">
import {
    FormSpecification,
    InputComponents,
    InputSpecification,
    InputType,
    SelectInputMode,
} from '@/components/InputMapper.vue'
import {
    formatEnum,
    formatLocalDateTime,
    formatLocaleNumber,
} from '@/util/formmater'
import { required } from '@/util/input-validation'
import ResourceTable from '@/components/ResourceTable.vue'
import {
    formulation,
    productRecommendTimeCase,
    recommendTimeWithMealCase,
    sourceType,
} from '@/constants'
import { ProductTag } from '@/store/tag'
import { defineComponent, Ref, ref, watch } from 'vue'
import { cloneDeep } from 'lodash-es'
import globalFormState from '@/components/globalFormState'
import CreateFormModal from '@/components/modal/CreateFormModal.vue'
import { ChatMessage } from '@/components/input/inputComplete'
import BulkActionModal from '@/components/modal/BulkActionModal.vue'
import { useEnumTypeStore } from '@/store/enumType'

const intakeMethodRegex = /\d{1,2}월|\d{1,3}일|\d주|\d{1,4}년|\d{1,3}회/

const preset: InputSpecification = {
    component: InputComponents.PresetInput,
    options: [
        {
            label: '멀티비타민',
            value: {
                nutrients: [
                    { nutrientName: '비타민A', unit: 'ug_RAE' },
                    { nutrientName: '비타민D', unit: 'ug' },
                    { nutrientName: '비타민E', unit: 'mg_aTE' },
                    { nutrientName: '비타민K', unit: 'ug' },
                    { nutrientName: '비타민C', unit: 'mg' },
                    { nutrientName: '비타민B1', unit: 'mg' },
                    { nutrientName: '비타민B2', unit: 'mg' },
                    { nutrientName: '비타민B6', unit: 'mg' },
                    { nutrientName: '엽산', unit: 'ug' },
                    { nutrientName: '비오틴', unit: 'ug' },
                    { nutrientName: '판토텐산', unit: 'mg' },
                    { nutrientName: '칼슘', unit: 'mg' },
                    { nutrientName: '마그네슘', unit: 'mg' },
                    { nutrientName: '아연', unit: 'mg' },
                    { nutrientName: '셀레늄', unit: 'ug' },
                    { nutrientName: '구리', unit: 'ug' },
                    { nutrientName: '망간', unit: 'mg' },
                    { nutrientName: '크롬', unit: 'ug' },
                ],
            },
        },
    ],
}

const chatMessage: ChatMessage[] = [
    {
        role: 'system',
        content: `You are json parameter generator. respond with only the raw JSON data, without any other additional explanations. `,
    },
    {
        role: 'system',
        content: `Refer to the following code to create JSON data. Detailed rules are written in the comments.

// Amounts type represent nutrient ingredients amount information
type Amounts = {
        nutrientName: string | null
        amount: number
        unit: string
}

// the Formulation type represent formulation information.
type Formulation = '정' | '캡슐' | '포' | '방울' | '티스푼' | '테이블스푼' | '스푼' | '스쿱' | 'ml' | 'mg' | 'g' | '병' | '앰플' | '환' | '구미' | '너겟' | '온즈' | '팩' | '스틱' | '스프레이' | '튜브' | '스포이드' | '매' | '조각' | '편' | '알' | '개'

/**
 * this is your generation target. you generate json data that match this type.
 * when fill formulation field, must match the Formulation type.
 * /
type InputSpec = {
    // 제품명
    productName: string | null
    // 영문 제품명
    productNameEn: string | null
    brandName: string | null
    calorie: number | null
    nutrients: Amounts[]
    // 1일 최소 섭취량
    dailyIntakeMin: number | null
    // 1일 최대 섭취량
    dailyIntakeMax: number | null
    // 제형
    formulation: Formulation | null
    // 서빙사이즈
    intakeCount: number | null
    // 1회 최소 섭취량
    oneTimeIntakeMin: number | null
    // 1회 최대 섭취량
    oneTimeIntakeMax: number | null
}

when you generate json data. make sure that the json data match the InputSpec type.
 `,
    },
]

export default defineComponent({
    name: 'ProductsView',
    components: {
        ResourceTable,
        CreateFormModal,
        BulkActionModal,
    },
    setup() {
        const { setFormValue, setFormValues, setInputSpec, formSpec } =
            globalFormState
        const createSpec: Ref<FormSpecification> = ref({
            preset: {
                ...cloneDeep(preset),
                value: null,
                input: (value: any) => {
                    const nutrients = value[0]?.nutrients
                    if (nutrients != null) {
                        setFormValue('nutrients', nutrients)
                    } else {
                        setFormValue('nutrients', [])
                    }
                },
            },
            inputComplete: {
                label: '제품정보 자동완성',
                value: null,
                component: InputComponents.InputCompleteInput,
                chatMessage: chatMessage,
                input: (value: any) => {
                    setFormValues(value)
                },
                help: 'ChatGPT를 사용하여 입력폼을 완성합니다. 입력 수고를 덜어줄 뿐. 입력에 정확도가 높지 않으니 주의하세요.',
            },
            productName: {
                label: '제품명',
                value: null,
                description: true,
            },
            status: {
                label: '제품 상태',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'product-status',
            },
            productNameEn: {
                label: '제품명(영어)',
                value: null,
                help: '해외제품인 경우 입력 필수',
            },
            regNum: {
                label: '식품이력추적번호',
                value: null,
                component: InputComponents.AInputNumber,
                description: true,
            },
            brandName: {
                label: '브랜드명',
                value: null,
                component: InputComponents.AutoCompleteInput,
                uri: '/api/brand/name',
            },
            sourceInfoUrl: {
                label: '제품 사이트 주소',
                value: null,
                input: (event: any) => {
                    const inputUrl: string = event?.target?.value

                    if (inputUrl.includes('danawa.com')) {
                        setFormValue('sourceType', 'D')
                        const match = inputUrl.match('[?&]pcode=([^&]+)')
                        const productId = match ? match[1] : null
                        setFormValue('sourceId', productId)
                    } else if (inputUrl.includes('iherb.com')) {
                        setFormValue('sourceType', 'I')
                        const splitedUrl = inputUrl.split('/')
                        const productId = splitedUrl[splitedUrl.length - 1]
                        setFormValue('sourceId', 'i' + productId)
                    } else {
                        setFormValue('sourceType', 'P')
                        setFormValue('sourceId', null)
                    }
                },
            },
            sourceImageUrl: {
                label: '제품 이미지 주소',
                value: null,
            },
            certification: {
                label: '인증사항',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'certification',
            },
            isGmp: {
                label: 'GMP 여부',
                value: null,
                component: InputComponents.BooleanInput,
                trueText: '인증받음',
                falseText: '알수없음',
                notNull: true,
                disabled: (record?: Record<string, any>) =>
                    record?.certification === 'OTC_DRUG',
            },
            tags: {
                label: '기능메인태그',
                value: null,
                component: InputComponents.ProductTagInput,
                description: true,
                select: (value: ProductTag) => {
                    if (formSpec.value.searchTags.value == null) {
                        setFormValue('searchTags', [])
                    }
                    formSpec.value.searchTags.value.push(...value.keywords)
                    setFormValue('searchTags', [
                        ...new Set(formSpec.value.searchTags.value),
                    ])
                },
                deselect: (value: ProductTag) => {
                    value.keywords.forEach((keyword) => {
                        const index =
                            formSpec.value.searchTags.value.indexOf(keyword)
                        formSpec.value.searchTags.value.splice(index, 1)
                    })
                },
                mode: SelectInputMode.MULTIPLE,
            },
            searchTags: {
                label: '제품서치태그',
                value: null,
                component: InputComponents.ASelect,
                mode: SelectInputMode.TAGS,
                help: '수정 불가',
            },
            customSearchTags: {
                label: '커스텀 서치태그',
                value: null,
                type: InputType.TEXTAREA,
                help: '운영자가 특정 제품에 추가적인 태그를 추가하고 싶은 경우 (마더브랜드(상위브랜드), 판매원, 제조사, 수입사, 브랜드 모델 등)',
            },
            calorie: {
                label: '칼로리',
                value: null,
                component: InputComponents.AInputNumber,
                min: 0,
                help: '단위 : kcal, 1회 제공량 기준',
            },
            nutrients: {
                label: '영양성분',
                value: [],
                component: InputComponents.ProductNutrientInput,
            },
            auxiliaryMaterial: {
                label: '부원료',
                value: null,
                type: InputType.TEXTAREA,
                help: '예시: 빌베리추출물 [Indena], 프랑스해안송껍질추출물 [피크노제놀]',
            },
            omegaThreeAmount: {
                label: '오메가3 총량 (g)',
                value: null,
                component: InputComponents.AInputNumber,
                help: '단위 : g',
            },
            buyNowUrl: {
                label: '구매 주소 URL',
                value: null,
                help: '구매 주소의 https:// 주소 입력란. 공백이면 구매 버튼 미노출',
            },
            buyNowDesktopUrl: {
                label: '웹구매주소(PC) URL',
                value: null,
                labelCol: 6,
            },
            buyNowWebMoUrl: {
                label: '웹구매주소(MO) URL',
                value: null,
                labelCol: 6,
            },
            buyNowTooltip: {
                label: '구매 주소 툴팁',
                value: null,
                help: '버튼 위에 노출되는 문구',
            },
            buyNowTooltipWebShow: {
                label: '브랜드스토어 툴팁 노출',
                value: null,
                component: InputComponents.BooleanInput,
                trueText: '노출',
                falseText: '비노출',
                help: '웹 구매버튼 위에 노출되는 문구 노출 여부',
            },
            customCoupangQuery: {
                label: '쿠팡 파트너스 검색 쿼리',
                value: null,
                help: '기본적인 조합 (제목 + 브랜드)로 검색이 되지 않을 경우 사용 (만약 항상 비노출을 원한다면 "Gold box" 를 입력)',
            },
            buyNowInCoupangAllowed: {
                label: '쿠팡 파트너스 바로구매 허용',
                value: null,
                component: InputComponents.BooleanInput,
                trueText: '노출',
                falseText: '비노출',
                help: '허용하더라도 약국 제품은 노출 안됨 (절대로 노출되지 않게 하기 위해서는 위 "쿠팡 파트너스 검색퀴리"에 "중지" 라고 입력해주세요',
            },
            productDetailImageUrl: {
                label: '제품 상세 설명 이미지 (유료)',
                value: null,
                help: '1.0.0 이전 버전용',
                labelCol: 6,
            },
            productDetailImageUrl1: {
                label: '제품 상세 설명 이미지 #1 (유료)',
                value: null,
                labelCol: 6,
            },
            productDetailImageUrl2: {
                label: '제품 상세 설명 이미지 #2 (유료)',
                value: null,
                labelCol: 6,
            },
            productDetailImageUrl3: {
                label: '제품 상세 설명 이미지 #3 (유료)',
                value: null,
                labelCol: 6,
            },
            memo1: {
                label: '메모(영양성분)',
                value: null,
                type: InputType.TEXTAREA,
                help: '제품정보에 나와있는 영양성분 이름이 검색되지 않으면, 여기에 써주세요',
            },
            intakeCount: {
                label: '서빙사이즈',
                value: null,
                component: InputComponents.AInputNumber,
            },
            formulation: {
                label: '제형',
                value: null,
                component: InputComponents.ASelect,
                options: formulation,
                description: true,
            },
            dailyIntakeMin: {
                label: '1일 최소 섭취 횟수',
                value: null,
                component: InputComponents.AInputNumber,
            },
            dailyIntakeMax: {
                label: '1일 최대 섭취 횟수',
                value: null,
                component: InputComponents.AInputNumber,
                help: '1 ~ 10',
                max: 10,
                input: (value: number) => {
                    if (value === 1) {
                        setFormValue('recommendTime1', '언제든')
                    } else if (value === 2) {
                        setFormValue('recommendTime1', '아침')
                        setFormValue('recommendTime2', '저녁')
                    } else if (value === 3) {
                        setFormValue('recommendTime1', '아침')
                        setFormValue('recommendTime2', '점심')
                        setFormValue('recommendTime3', '저녁')
                    } else if (value === 4) {
                        setFormValue('recommendTime1', '아침')
                        setFormValue('recommendTime2', '점심')
                        setFormValue('recommendTime3', '저녁')
                        setFormValue('recommendTime4', '자기전')
                    }
                },
            },
            onetimeIntakeMin: {
                label: '1회 최소 복용량',
                value: null,
                component: InputComponents.AInputNumber,
            },
            onetimeIntakeMax: {
                label: '1회 최대 복용량',
                value: null,
                component: InputComponents.AInputNumber,
            },
            intakeMethod: {
                label: '섭취방법',
                value: null,
                description: true,
            },
            intakeGuide: {
                label: '섭취안내문',
                value: null,
                formChangeHook: (form: Record<string, any>) => {
                    const {
                        dailyIntakeMin,
                        dailyIntakeMax,
                        onetimeIntakeMin,
                        onetimeIntakeMax,
                        formulation,
                        intakeMethod,
                    } = form
                    const dailyIntakeMaxTemplate =
                        (dailyIntakeMax != null &&
                            dailyIntakeMax != '' &&
                            `- 최대 ${dailyIntakeMax}회까지`) ||
                        '이상'

                    if (
                        dailyIntakeMin != dailyIntakeMax &&
                        onetimeIntakeMin != onetimeIntakeMax
                    ) {
                        setFormValue(
                            'intakeGuide',
                            `
              하루에 최소 ${dailyIntakeMin || ''}회 ${dailyIntakeMaxTemplate}
              1회당 최소${onetimeIntakeMin || ''}${formulation || ''} - 최대 ${
                                onetimeIntakeMax || ''
                            }${formulation || ''}까지 ${
                                (intakeMethod && intakeMethod + ' ') || ''
                            }${
                                (formulation?.includes(
                                    '스프레이',
                                    '스포이드'
                                ) &&
                                    '사용하세요') ||
                                '드세요'
                            }`
                        )
                    } else if (
                        dailyIntakeMin == dailyIntakeMax &&
                        onetimeIntakeMin == onetimeIntakeMax &&
                        intakeMethod?.match(intakeMethodRegex)
                    ) {
                        setFormValue(
                            'intakeGuide',
                            `1회당 ${onetimeIntakeMin || ''}${
                                formulation || ''
                            }씩 ${(intakeMethod && intakeMethod + ' ') || ''}${
                                (formulation?.includes(
                                    '스프레이',
                                    '스포이드'
                                ) &&
                                    '사용하세요') ||
                                '드세요'
                            }`
                        )
                    } else if (
                        dailyIntakeMin == null &&
                        dailyIntakeMax == null &&
                        onetimeIntakeMin != onetimeIntakeMax
                    ) {
                        setFormValue(
                            'intakeGuide',
                            `1회당 최소 ${onetimeIntakeMin || ''}${
                                formulation || ''
                            } - 최대 ${onetimeIntakeMax || ''}${
                                formulation || ''
                            }까지 ${
                                (intakeMethod && intakeMethod + ' ') || ''
                            }${
                                (formulation?.includes(
                                    '스프레이',
                                    '스포이드'
                                ) &&
                                    '사용하세요') ||
                                '드세요'
                            }`
                        )
                    } else if (
                        dailyIntakeMin == dailyIntakeMax &&
                        onetimeIntakeMin == onetimeIntakeMax
                    ) {
                        setFormValue(
                            'intakeGuide',
                            `하루에 ${dailyIntakeMin || ''}회, 1회당 ${
                                onetimeIntakeMin || ''
                            }${formulation || ''}씩 ${
                                (intakeMethod && intakeMethod + ' ') || ''
                            }${
                                (formulation?.includes(
                                    '스프레이',
                                    '스포이드'
                                ) &&
                                    '사용하세요') ||
                                '드세요'
                            }`
                        )
                    } else if (
                        dailyIntakeMin == dailyIntakeMax &&
                        onetimeIntakeMin != onetimeIntakeMax
                    ) {
                        setFormValue(
                            'intakeGuide',
                            `하루에 ${dailyIntakeMin || ''}회, 1회당 최소 ${
                                onetimeIntakeMin || ''
                            }${formulation || ''} - 최대 ${
                                onetimeIntakeMax || ''
                            }${formulation || ''}까지 ${
                                (intakeMethod && intakeMethod + ' ') || ''
                            }${
                                (formulation.includes('스프레이', '스포이드') &&
                                    '사용하세요') ||
                                '드세요'
                            }`
                        )
                    } else if (
                        dailyIntakeMin != dailyIntakeMax &&
                        onetimeIntakeMin == null &&
                        onetimeIntakeMax != null
                    ) {
                        setFormValue(
                            'intakeGuide',
                            `하루에 최소 ${
                                dailyIntakeMin || ''
                            }회 ${dailyIntakeMaxTemplate} 1회당 최대 ${
                                onetimeIntakeMax || ''
                            }${formulation || ''}까지 ${
                                (intakeMethod && intakeMethod + ' ') || ''
                            }${
                                (formulation?.includes(
                                    '스프레이',
                                    '스포이드'
                                ) &&
                                    '사용하세요') ||
                                '드세요'
                            }`
                        )
                    } else if (
                        dailyIntakeMin != dailyIntakeMax &&
                        onetimeIntakeMin == onetimeIntakeMax
                    ) {
                        setFormValue(
                            'intakeGuide',
                            `하루에 최소 ${
                                dailyIntakeMin || ''
                            }회 ${dailyIntakeMaxTemplate} ${
                                onetimeIntakeMin || ''
                            }${formulation || ''}씩 ${
                                (intakeMethod && intakeMethod + ' ') || ''
                            }${
                                (formulation?.includes(
                                    '스프레이',
                                    '스포이드'
                                ) &&
                                    '사용하세요') ||
                                '드세요'
                            }`
                        )
                    }
                },
                readonly: true,
            },
            recommendTime1: {
                label: '추천 섭취시간1',
                value: null,
                component: InputComponents.ASelect,
                options: productRecommendTimeCase,
                description: true,
                disabled: (record?: Record<string, any>) => {
                    if (record?.dailyIntakeMin >= 1) {
                        return false
                    }
                    setFormValue('recommendTime1', null)
                    return true
                },
            },
            recommendTimeWithMeal1: {
                label: '식전/식후1',
                value: null,
                component: InputComponents.ASelect,
                options: recommendTimeWithMealCase,
            },
            recommendTime2: {
                label: '추천 섭취시간2',
                value: null,
                component: InputComponents.ASelect,
                options: productRecommendTimeCase,
                disabled: (record?: Record<string, any>) => {
                    if (record?.dailyIntakeMin >= 2) {
                        return false
                    }
                    setFormValue('recommendTime2', null)
                    return true
                },
            },
            recommendTimeWithMeal2: {
                label: '식전/식후2',
                value: null,
                component: InputComponents.ASelect,
                options: recommendTimeWithMealCase,
            },
            recommendTime3: {
                label: '추천 섭취시간3',
                value: null,
                component: InputComponents.ASelect,
                options: productRecommendTimeCase,
                disabled: (record?: Record<string, any>) => {
                    if (record?.dailyIntakeMin >= 3) {
                        return false
                    }
                    setFormValue('recommendTime3', null)
                    return true
                },
            },
            recommendTimeWithMeal3: {
                label: '식전/식후3',
                value: null,
                component: InputComponents.ASelect,
                options: recommendTimeWithMealCase,
            },
            recommendTime4: {
                label: '추천 섭취시간4',
                value: null,
                component: InputComponents.ASelect,
                options: productRecommendTimeCase,
                disabled: (record?: Record<string, any>) => {
                    if (record?.dailyIntakeMin === 4) {
                        return false
                    }
                    setFormValue('recommendTime4', null)
                    return true
                },
            },
            intakeNote1: {
                label: '섭취노트1',
                value: '부작용이 느껴지면 섭취량을 줄이거나 섭취를 중단하세요',
                description: true,
            },
            intakeNote2: { label: '각주', value: null },
            intakeNote3: { label: '섭취노트3', value: null },
            memo2: {
                label: '메모(추가정보)',
                value: null,
                type: InputType.TEXTAREA,
                help: '검색되지 않거나 상세설명이 필요한 경우, 여기에 써주세요',
            },
            gmoStatus: {
                label: 'GMO',
                value: 'UNKNOWN',
                component: InputComponents.EnumSelect,
                typeName: 'additive-status',
                disabled: true,
                hidden: true,
            },
            artificialDyeStatus: {
                label: '인공색소',
                value: 'UNKNOWN',
                component: InputComponents.EnumSelect,
                typeName: 'additive-status',
                disabled: true,
                hidden: true,
            },
            artificialSpiceStatus: {
                label: '인공향료',
                value: 'UNKNOWN',
                component: InputComponents.EnumSelect,
                typeName: 'additive-status',
                disabled: true,
                hidden: true,
            },
            memo3: {
                label: '메모(식품이력추적)',
                value: null,
                type: InputType.TEXTAREA,
                help: 'GMO, 색소, 향료에 관한 내용을 남겨주세요:)',
            },
            targetGender: {
                label: '타겟 성별',
                value: 'UNISEX',
                component: InputComponents.EnumSelect,
                typeName: 'gender',
            },
            targetAge: {
                label: '타겟 나이대',
                value: 'AGE_ALL',
                component: InputComponents.EnumSelect,
                typeName: 'age-range',
            },
            targetHealthStatus: {
                label: '타겟 건강상태',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'health-condition',
                mode: SelectInputMode.TAGS,
            },
            consumerGuides: {
                label: '연구소 팁',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'consumer-guide',
            },
            sourceType: {
                label: '원본유형',
                value: null,
                component: InputComponents.ASelect,
                options: sourceType,
                description: true,
                input: (value: string) => {
                    if (value === 'P') {
                        setFormValue('sourceType', 'P')
                        setFormValue('sourceId', null)
                    }
                },
            },
            sourceId: {
                label: '원본 ID',
                value: null,
                description: true,
                disabled: (record?: Record<string, any>) => {
                    if (record?.sourceType === 'P') {
                        setFormValue('sourceId', null)
                        return true
                    }
                    return false
                },
            },
            renewedId: {
                label: '리뉴얼된 제품 ID',
                value: null,
                description: true,
                component: InputComponents.ProductInput,
                help: '리뉴얼된 제품 ID를 입력하면, 이 제품은 단종으로 처리됩니다. 만약 현재 제품과 같은 제품을 선택 하면 무효화 됩니다',
            },
        })

        const updateSpec = { ...createSpec.value }

        const mergingSpec: FormSpecification = {
            productId: {
                label: '숨김 대상 제품ID',
                value: '',
                component: InputComponents.ProductInput,
                uri: '/api/products/product-name',
                mode: SelectInputMode.DEFAULT,
                width: '300px',
            },
            mergingProductId: {
                label: '병합 대상 제품ID',
                value: '',
                component: InputComponents.ProductInput,
                uri: '/api/products/product-name',
                mode: SelectInputMode.DEFAULT,
                width: '300px',
            },
            adminVerification: {
                label: '확인',
                value: null,
                help: '"병합 후 숨김" 이라고 적어주세요',
            },
        }

        const mergingRules = {
            productId: [required],
            mergingProductId: [required],
            adminVerification: [required],
        }

        const rules = {
            productName: [required],
            brandName: [required],
            sourceImageUrl: [required],
            intakeCount: [required],
            formulation: [required],
            certification: [required],
            dailyIntakeMin: [required],
            dailyIntakeMax: [required],
            onetimeIntakeMin: [required],
            onetimeIntakeMax: [required],
            tags: [required],
            sourceId: [
                {
                    validator: (rule: string, value: string) => {
                        if (formSpec.value.sourceType.value === 'I') {
                            if (!value.startsWith('i')) {
                                return Promise.reject(
                                    new Error(
                                        "원본 타입이 I일 경우 원본ID는 'i'로 시작해야합니다."
                                    )
                                )
                            }
                        }
                        if (formSpec.value.sourceType.value === 'D') {
                            if (!value) {
                                return Promise.reject(
                                    new Error(
                                        '원본 타입이 D일 경우 원본ID를 입력해야합니다.'
                                    )
                                )
                            }
                        }
                        return Promise.resolve()
                    },
                    trigger: 'blur',
                },
            ],
            nutrients: [
                {
                    validator: (rule: string, value: any) => {
                        if (value instanceof Array) {
                            for (const nutrient of value) {
                                if (nutrient.amount == null) {
                                    return Promise.reject(
                                        new Error('영양소 수치 미입력')
                                    )
                                }

                                if (
                                    value.filter(
                                        (it: any) =>
                                            it.nutrientName ===
                                            nutrient.nutrientName
                                    ).length > 1
                                ) {
                                    return Promise.reject(
                                        new Error(
                                            `${nutrient.nutrientName} 영양소가 중복되었습니다. 중복된 영양소는 등록할 수 없습니다.`
                                        )
                                    )
                                }
                            }
                        }
                        return Promise.resolve()
                    },
                },
            ],
        }

        watch(
            () => cloneDeep(formSpec.value),
            (currentSpec: FormSpecification) => {
                if (!currentSpec.dailyIntakeMin) {
                    return
                }
                if (
                    currentSpec.dailyIntakeMin.value !=
                        currentSpec.dailyIntakeMax.value ||
                    currentSpec.onetimeIntakeMin.value !=
                        currentSpec.onetimeIntakeMax.value
                ) {
                    setInputSpec('intakeNote2', {
                        value: '처음에는 최소량만 섭취하시다가 눈에 띄는 부작용이 없다면 최대량까지 천천히 늘려서 드시는 걸 추천드려요',
                        disabled: true,
                        help: '하루 취소 섭취량과 최대 섭취량, 1회 최소 섭취량과 최대 섭취량이 같으면 변경할 수 없습니다.',
                    })
                } else {
                    setInputSpec('intakeNote2', {
                        disabled: false,
                        help: null,
                    })
                }
            }
        )

        const rowSelection = ref({
            selectedRowKeys: [],
            selectedRows: [],
            onChange: (selectedRowKeys: any, selectedRows: any) => {
                rowSelection.value.selectedRowKeys = selectedRowKeys
                rowSelection.value.selectedRows = selectedRows
            },
        })

        const consumerGuideSpec = ref({
            consumerGuideName: {
                label: '연구소 팁',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'consumer-guide',
            },
        })

        return {
            createSpec,
            updateSpec,
            rules,
            mergingSpec,
            mergingRules,
            rowSelection,
            consumerGuideSpec,
        }
    },
    data() {
        return {
            filterSpec: {
                id: { label: 'ID', value: null, component: 'AInputNumber' },
                sourceId: { label: '원본 ID', value: null },
                productName: { label: '제품명', value: null },
                brandName: {
                    label: '브랜드명',
                    value: null,
                    component: InputComponents.AutoCompleteInput,
                    uri: '/api/brand/name',
                },
                certification: {
                    label: '인증사항',
                    value: null,
                    component: 'EnumSelect',
                    typeName: 'certification',
                },
                nutrientNameList: {
                    label: '영양소 명',
                    value: [],
                    component: 'NutrientInput',
                    mode: SelectInputMode.MULTIPLE,
                },
                tagNames: {
                    label: '태그',
                    value: null,
                    component: 'ProductTagInput',
                    mode: SelectInputMode.DEFAULT,
                },
                hasBuyNowUrl: {
                    label: '구매링크만',
                    value: null,
                    component: InputComponents.BooleanInput,
                    toggle: true,
                    labelCol: 7,
                },
                buyNowUrl: {
                    label: '구매링크',
                    value: null,
                    labelCol: 6,
                },
                buyNowDesktopUrl: {
                    label: '구매링크(데스크탑)',
                    value: null,
                    labelCol: 8,
                },
                consumerGuideName: {
                    label: '연구소 팁',
                    value: null,
                    component: InputComponents.EnumSelect,
                    typeName: 'consumer-guide',
                },
                sourceType: {
                    label: '원본유형',
                    value: null,
                },
            },
            recommendTimeWithMealCase,
            productRecommendTimeCase,
            sourceType,
            formulation,
            preset: {
                component: 'PresetInput',
                options: [
                    {
                        label: '멀티비타민',
                        value: {
                            nutrients: [
                                { nutrientName: '비타민A', unit: 'ug_RAE' },
                                { nutrientName: '비타민D', unit: 'ug' },
                                { nutrientName: '비타민E', unit: 'mg_aTE' },
                                { nutrientName: '비타민K', unit: 'ug' },
                                { nutrientName: '비타민C', unit: 'mg' },
                                { nutrientName: '비타민B1', unit: 'mg' },
                                { nutrientName: '비타민B2', unit: 'mg' },
                                { nutrientName: '나이아신', unit: 'mg_NE' },
                                { nutrientName: '비타민B6', unit: 'mg' },
                                { nutrientName: '비타민B12', unit: 'ug' },
                                { nutrientName: '엽산', unit: 'ug' },
                                { nutrientName: '비오틴', unit: 'ug' },
                                { nutrientName: '판토텐산(B5)', unit: 'mg' },
                                { nutrientName: '칼슘', unit: 'mg' },
                                { nutrientName: '마그네슘', unit: 'mg' },
                                { nutrientName: '아연', unit: 'mg' },
                                { nutrientName: '셀레늄', unit: 'ug' },
                                { nutrientName: '구리', unit: 'ug' },
                                { nutrientName: '망간', unit: 'mg' },
                                { nutrientName: '크롬', unit: 'ug' },
                            ],
                        },
                    },
                ],
            },
            columns: [
                {
                    title: '제품상태',
                    dataIndex: 'status',
                    width: 80,
                    fixed: 'left',
                    customRender: ({
                        record,
                        text,
                    }: {
                        record: Record<string, any>
                        text: any
                    }) => {
                        if (record.status === 'NORMAL') {
                            return '정상'
                        } else if (record.status === 'BLINDED') {
                            return '숨김🙈'
                        }
                        return '??'
                    },
                },
                {
                    title: 'ID',
                    dataIndex: 'id',
                    width: 80,
                    fixed: 'left',
                    customFilterDropdown: true,
                    sorter: true,
                },
                {
                    title: '이미지',
                    width: 130,
                    dataIndex: 'productThumbnailImageUrl',
                    type: 'img',
                },
                {
                    title: '원본 제품 정보',
                    width: 80,
                    dataIndex: 'sourceInfoUrl',
                    type: 'link',
                },
                {
                    title: '제품명',
                    dataIndex: 'productName',
                    customFilterDropdown: true,
                    sorter: true,
                    width: 220,
                },
                {
                    title: '제품명(영어)',
                    dataIndex: 'productNameEn',
                    customFilterDropdown: true,
                    sorter: true,
                    width: 130,
                },
                {
                    title: '브랜드명',
                    dataIndex: 'brandName',
                    sorter: true,
                    customFilterDropdown: true,
                    width: 100,
                },
                {
                    title: '태그',
                    dataIndex: 'tags',
                    width: 200,
                    sorter: true,
                    sorterName: 'tagNames',
                    type: 'tags',
                },
                {
                    title: '제품상태',
                    dataIndex: 'status',
                    width: 60,
                    customRender: ({
                        record,
                        text,
                    }: {
                        record: Record<string, any>
                        text: any
                    }) => {
                        if (record.status === 'NORMAL') {
                            return '정상'
                        } else if (record.status === 'BLINDED') {
                            return '숨김🙈'
                        }
                        return '??'
                    },
                },
                {
                    title: 'GMP 여부',
                    dataIndex: 'isGmp',
                    width: 80,
                    customRender: ({
                        record,
                        text,
                    }: {
                        record: Record<string, any>
                        text: any
                    }) => {
                        if (record.isCgmp === true) {
                            return '인증받음'
                        }
                        if (text === true) {
                            return '인증받음'
                        }
                        return '알수없음'
                    },
                },
                {
                    title: '인증사항',
                    dataIndex: 'certification',
                    width: 100,
                    customRender: formatEnum('certification'),
                },
                {
                    title: '서빙사이즈',
                    dataIndex: 'intakeCount',
                    align: 'right',
                    width: 100,
                    sorter: true,
                },
                {
                    title: '제형',
                    dataIndex: 'formulation',
                    width: 100,
                    sorter: true,
                },
                {
                    title: '일일 최소 섭취수',
                    dataIndex: 'dailyIntakeMin',
                    align: 'right',
                    width: 130,
                    customRender: formatLocaleNumber,
                    sorter: true,
                },
                {
                    title: '일일 최대 섭취수',
                    dataIndex: 'dailyIntakeMax',
                    align: 'right',
                    width: 130,
                    customRender: formatLocaleNumber,
                    sorter: true,
                },
                {
                    title: '일회 복용량',
                    dataIndex: 'onetimeIntakeMin',
                    align: 'right',
                    width: 130,
                    customRender: formatLocaleNumber,
                    sorter: true,
                },
                {
                    title: '일회 최대 복용량',
                    dataIndex: 'onetimeIntakeMax',
                    align: 'right',
                    width: 130,
                    customRender: formatLocaleNumber,
                    sorter: true,
                },
                {
                    title: '리뷰 등록수',
                    dataIndex: 'reviewRegistrationCount',
                    align: 'right',
                    width: 80,
                    customRender: formatLocaleNumber,
                },
                {
                    title: '섭취알림 등록수',
                    dataIndex: 'intakeNotificationRegistrationCount',
                    align: 'right',
                    width: 80,
                    customRender: formatLocaleNumber,
                },
                {
                    title: '등록일시',
                    dataIndex: 'creationTime',
                    customRender: formatLocalDateTime,
                    width: 165,
                    sorter: true,
                    align: 'center',
                },
                {
                    title: '수정일시',
                    dataIndex: 'updateTime',
                    customRender: formatLocalDateTime,
                    width: 165,
                    sorter: true,
                    align: 'center',
                },
            ],
        }
    },

    methods: {
        rowDeletable(record: Record<string, any>): boolean {
            const count =
                record.reviewRegistrationCount +
                record.intakeNotificationRegistrationCount
            return count === 0
        },
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums([
            'completion-level',
            'additive-status',
            'product-status',
            'certification',
            'recommend-time-case',
            'recommend-time-with-meal-case',
            'gender',
            'age-range',
            'health-condition',
            'consumer-guide',
        ])
    },
})
</script>

import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tbg-container"
}
const _hoisted_2 = {
  key: 1,
  class: "tbg-container"
}
const _hoisted_3 = { class: "meal-container" }
const _hoisted_4 = { class: "meal-container" }
const _hoisted_5 = { class: "meal-container" }
const _hoisted_6 = { style: {"float":"right"} }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 1,
  style: {"margin-right":"10px"}
}
const _hoisted_9 = { style: {"float":"right"} }
const _hoisted_10 = {
  key: 0,
  style: {"margin-right":"10px"}
}
const _hoisted_11 = ["href"]
const _hoisted_12 = {
  class: "survey-check-container",
  style: {"display":"flex","margin-top":"20px"}
}
const _hoisted_13 = { class: "wm-container" }
const _hoisted_14 = {
  key: 0,
  class: "wm-assign-message"
}
const _hoisted_15 = { style: {"float":"right"} }
const _hoisted_16 = { style: {"margin-right":"10px"} }
const _hoisted_17 = {
  key: 0,
  style: {"margin-right":"10px"}
}
const _hoisted_18 = { class: "modal-content-container" }
const _hoisted_19 = { class: "modal-content-container content-cpf" }
const _hoisted_20 = { class: "modal-content-section carbohydrate-section" }
const _hoisted_21 = { class: "modal-input plain-text" }
const _hoisted_22 = { class: "modal-content-section protein-section" }
const _hoisted_23 = { class: "modal-input plain-text" }
const _hoisted_24 = { class: "modal-input plain-text" }
const _hoisted_25 = { class: "modal-content-section fat-section" }
const _hoisted_26 = { class: "modal-input plain-text" }

import { computed, onMounted, ref } from 'vue'
import {
    FormSpecification,
    InputComponents,
    InputType,
} from '@/components/InputMapper.vue'
import WeekSelectInput from '@/components/input/WeekSelectInput.vue'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { getDateList } from '@/util/date-util'
import NestedResourceTable from '@/components/NestedResourceTable.vue'
import {
    formatKoreanTimeOnlyTime,
    formatLocalDate,
    formatLocalDateTime,
} from '@/util/formmater'
import { differenceInDays, format, isBefore } from 'date-fns'
import { ko } from 'date-fns/locale'
import { Button, MenuProps, message, Tag } from 'ant-design-vue'
import {
    EyeInvisibleOutlined,
    EyeOutlined,
    LinkOutlined,
    PlusOutlined,
    SendOutlined,
} from '@ant-design/icons-vue'
import globalFormState from '@/components/globalFormState'
import { required } from '@/util/input-validation'
import { stringify } from 'qs'
import { debounce } from 'lodash'
import Draggable from 'vuedraggable'
import Tiptap from '@/components/input/tiptap/Tiptap.vue'
import { useFormeWeekStore } from '@/store/formeWeek'
import { useEnumTypeStore } from '@/store/enumType'
import useChatApp from '@/components/chat/useChatApp'
import EnumSelect from '@/components/input/EnumSelect.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'UserDietProgramEdit',
  props: {
    userId: {
        type: Number,
        required: true,
    },
},
  setup(__props) {

const props = __props

const filterParams = ref<Record<string, any>>({
    weekNumber: 1,
})

const loading = ref(false)
const findFactorFail = ref(false)
const table = ref<any>(null)
const dataTable = ref<any>(null)

const hideWeeklyMission = async (id: string) => {
    loading.value = true
    await adminApi(`/api/forme/diet/mission/hide/${props.userId}/${id}`, {
        method: 'POST',
    })
    await fetchTable()
    loading.value = false
}

const { formeWeek, getWeekList } = useFormeWeekStore()

const columns = computed(() => {
    const selectedWeek = formeWeek.selectedWeek
    const dateTileList =
        selectedWeek != null
            ? getDateList(selectedWeek.startDate, selectedWeek.endDate).map(
                  (it, index) => ({
                      title: format(it, 'M.d(EEE)', { locale: ko }),
                      dataIndex: `day${index + 1}`,
                      align: 'center',
                      customRender: (column: any) => {
                          if (column.value) {
                              return '✅'
                          } else {
                              return ''
                          }
                      },
                  })
              )
            : []
    return [
        {
            title: 'ID',
            dataIndex: 'userMissionId',
            width: 80,
            fixed: 'left',
        },
        {
            title: '타입',
            dataIndex: 'type',
        },
        {
            title: '종류',
            dataIndex: 'category',
        },
        {
            title: '미션',
            dataIndex: 'name',
            customRender: ({
                value,
                record,
            }: {
                value: any
                record: Record<string, any>
            }) => {
                if (value.includes('{변수}')) {
                    return value.replace('{변수}', record.value)
                }
                return value
            },
            customCell: (record: Record<any, any>) => {
                if (record.feedbackScore === 1) {
                    return {
                        style: {
                            backgroundColor: '#FFF9DE',
                        },
                    }
                } else if (record.feedbackScore === 5) {
                    return {
                        style: {
                            backgroundColor: '#FFEFF2',
                        },
                    }
                }
            },
        },
        {
            title: '가중치 점수',
            dataIndex: 'weight',
        },
        ...dateTileList,
        {
            title: '달성률',
            dataIndex: 'completeRatio',
            align: 'center',
            customRender: ({ value, record }: any) => {
                return `${value}%`
            },
            customCell: (record: any) => {
                const ratio = record.completeRatio
                if (ratio >= 85) {
                    return {
                        style: {
                            backgroundColor: '#efffff',
                        },
                    }
                } else if (ratio >= 70 && ratio < 85) {
                    return {
                        style: {
                            backgroundColor: '#effff4',
                        },
                    }
                } else if (ratio >= 55 && ratio < 70) {
                    return {
                        style: {
                            backgroundColor: '#fff9de',
                        },
                    }
                }
                return {
                    style: {
                        backgroundColor: '#ffeff2',
                    },
                }
            },
        },
        {
            title: '수정 날짜',
            dataIndex: 'updatedAt',
            customRender: formatLocalDate,
        },
        {
            title: '상태',
            dataIndex: 'hidden',
            customRender: ({
                value,
                record,
            }: {
                value: any
                record: Record<any, any>
            }) => {
                return value ? (
                    <Button
                        style="background-color: #9ba1aa; color: white"
                        loading={loading.value}
                        size="middle"
                        onClick={async () =>
                            await hideWeeklyMission(record.userMissionId)
                        }
                    >
                        {loading.value == false && <EyeInvisibleOutlined />}
                    </Button>
                ) : (
                    <Button loading={loading.value} size="middle">
                        <EyeOutlined
                            onClick={async () =>
                                await hideWeeklyMission(
                                    record.userMissionId
                                ).then()
                            }
                        />
                    </Button>
                )
            },
            align: 'center',
        },
        {
            title: '수정',
            type: 'edit',
            align: 'center',
            fixed: 'right',
        },
    ]
})

const monitorColumnNames: Record<string, any> = {
    weight: {
        name: '몸무게',
        format: (value: number) => {
            if (value == null || value == 0) {
                return ''
            }
            return `${Number(value).toFixed(1)}kg`
        },
    },
    height: {
        name: '키',
        format: (value: number) => `${Number(value).toFixed(1)}cm`,
    },
    pbf: {
        name: '체지방률',
        format: (value: number) => `${Number(value).toFixed(1)}%`,
    },
    smm: {
        name: '골격근량',
        format: (value: number) => `${Number(value).toFixed(1)}kg`,
    },
    glucoseScore: {
        name: '혈당점수',
        format: (value: number) => `${value}점`,
    },
    maxGlucoseLevel: {
        name: '최고혈당',
        format: (value: number) => `${Number(value).toFixed(0)}mg/dL`,
    },
    spikeCount: {
        name: '혈당스파이크횟수',
        format: (value: any) => `${value}회`,
    },
    highBloodGlucoseDuration: {
        name: '고혈당노출시간',
        format: (value: any) => `${value}분`,
    },
    lowBloodGlucoseDuration: {
        name: '저혈당노출시간',
        format: (value: any) => `${value}분`,
    },
    avgGlucoseLevel: {
        name: '평균혈당',
        format: (value: number) => `${Number(value).toFixed(0)}mg/dL`,
    },
    fbg: {
        name: '공복혈당',
        format: (value: number) => `${Number(value).toFixed(0)}mg/dL`,
    },
    cvGlucoseLevel: {
        name: '혈당변동성',
        format: (value: number) => `${Number(value).toFixed(0)}%`,
    },
    healthyLevelRatio: {
        name: '건강혈당범위',
        format: (value: any) => `${Number(value).toFixed(0)}%`,
    },
    dietScore: {
        name: '식단점수',
        format: (value: any) => `${value}점`,
    },
    intakeCalorie: {
        name: '섭취칼로리(kcal)',
        format: (value: any) => `${value}kcal`,
    },
    intakeCalorieRatio: {
        name: '섭취칼로리(%)',
        format: (value: any) => `${Number(value).toFixed(0)}%`,
    },
    carbohydrateAmount: {
        name: '탄수화물(g)',
        format: (value: any) => `${Number(value).toFixed(0)}g`,
    },
    carbohydrateRatio: {
        name: '탄수화물(%)',
        format: (value: any) => `${Number(value).toFixed(0)}%`,
    },
    proteinAmount: {
        name: '단백질(g)',
        format: (value: any) => `${Number(value).toFixed(0)}g`,
    },
    proteinRatio: {
        name: '단백질(%)',
        format: (value: any) => `${Number(value).toFixed(0)}%`,
    },
    fatAmount: {
        name: '지방(g)',
        format: (value: any) => `${Number(value).toFixed(0)}g`,
    },
    fatRatio: {
        name: '지방(%)',
        format: (value: any) => `${Number(value).toFixed(0)}%`,
    },
    macronutrientRatio: {
        name: '탄단지 비율',
        format: (value: any) => `${value}`,
    },
    sugarAmount: {
        name: '당류(g)',
        format: (value: any) => `${Number(value).toFixed(0)}g`,
    },
    alcoholAmount: {
        name: '알코올(g)',
        format: (value: any) => `${Number(value).toFixed(0)}g`,
    },
    caffeineAmount: {
        name: '카페인(mg)',
        format: (value: any) => `${Number(value).toFixed(0)}mg`,
    },
    intakeCount: {
        name: '끼니수',
        format: (value: any) => `${value}회`,
    },
    activeCalorie: {
        name: '활동칼로리(kcal)',
        format: (value: any) => `${value}kcal`,
    },
    workoutList: {
        name: '운동 종류',
        format: (value: string) => {
            const workoutList = [...new Set(JSON.parse(value))]
            return (
                <span>
                    {workoutList.map((it: any) => (
                        <Tag>{it}</Tag>
                    ))}
                </span>
            )
        },
        //JSON.parse(value).map((it: string) => <Tag>{it}</Tag>),
    },
    zone1Minute: {
        name: 'zone1 운동시간',
        format: (value: any) => `${value}분`,
    },
    zone2Minute: {
        name: 'zone2 운동시간',
        format: (value: any) => `${value}분`,
    },
    zone3Minute: {
        name: 'zone3 운동시간',
        format: (value: any) => `${value}분`,
    },
    zone4Minute: {
        name: 'zone4 운동시간',
        format: (value: any) => `${value}분`,
    },
    zone5Minute: {
        name: 'zone5 운동시간',
        format: (value: any) => `${value}분`,
    },
    goeZone2Minute: {
        name: 'zone2 이상 운동시간',
        format: (value: any) => `${value}분`,
    },
    fastingTime: {
        name: '단식시간',
        format: (value: any) => `${Number(value / 3600).toFixed(0)}시간`,
    },
    fastingCompleteRate: {
        name: '단식달성률',
        format: (value: any) => `${value}%`,
    },
    firstIntakeAt: {
        name: '첫 끼니시간',
        format: formatKoreanTimeOnlyTime,
    },
    lastIntakeAt: {
        name: '마지막 끼니시간',
        format: formatKoreanTimeOnlyTime,
    },
    workoutTotalTime: {
        name: '총 운동 시간',
        format: (value: any) =>
            `${Number(Math.floor(value / 60)).toFixed(0)}분`,
    },
    dailySteps: {
        name: '걸음수',
        format: (value: any) => `${value}걸음`,
    },
    workoutStartAt: {
        name: '마지막 운동 시작 시간',
        format: formatLocalDateTime,
    },
    sleepMinute: {
        name: '수면지속시간',
        format: (value: any) => `${Number(value / 60).toFixed(0)}시간`,
    },
    sleepStartAt: {
        name: '취침 시간',
        format: formatLocalDateTime,
    },
    targetDate: {
        name: '대상날짜',
        format: formatLocalDate,
    },
}

const colorPalate: Record<any, string> = {
    blue: '#EFFFFF',
    green: '#EFFFF4',
    yellow: '#FFF9DE',
    red: '#FFEFF2',
}

const monitorColumns = computed(() => {
    const selectedWeek = formeWeek.selectedWeek
    const dateTitleList =
        selectedWeek != null
            ? getDateList(selectedWeek.startDate, selectedWeek.endDate).map(
                  (it, index) => ({
                      title: format(it, 'M.d(EEE)', { locale: ko }),
                      dataIndex: `day${index + 1}`,
                      align: 'center',
                      customRender: ({
                          value,
                          record,
                      }: {
                          value: any
                          record: Record<any, any>
                      }) => {
                          if (
                              value &&
                              monitorColumnNames[record.property] != null &&
                              monitorColumnNames[record.property].format
                          ) {
                              return monitorColumnNames[
                                  record.property
                              ]?.format(value)
                          }
                      },
                      customCell: (record: Record<any, string>) => {
                          if (record[`day${index + 1}Color`]) {
                              return {
                                  style: {
                                      backgroundColor:
                                          colorPalate[
                                              record[`day${index + 1}Color`]
                                          ],
                                  },
                              }
                          }
                      },
                  })
              )
            : []

    return [
        {
            title: '',
            dataIndex: 'property',
            customRender: (column: { value: string }) => {
                return monitorColumnNames[column.value]?.name
            },
        },
        ...dateTitleList,
        {
            title: '실패 일수',
            dataIndex: 'failCount',
        },
        {
            title: '주간 데이터',
            dataIndex: 'weekValue',
        },
    ]
})

const userDetail = ref<any>({})
const setUserDetail = async (userId: number) => {
    const response = await adminApi<ApiResponse<any>>(
        `/api/forme/diet/user/${userId}?weekNumber=${
            filterParams.value.weekNumber || 1
        }`
    )
    userDetail.value = response.result || {}
}
const updateDietFactorType = async () => {
    try {
        await adminApi(`/api/forme/diet/diet-factor/${props.userId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                dietFactorTypeList: userDetail.value.dietFactorTypeList.map(
                    (it: any) => it.dietFactorType
                ),
            }),
        })
    } catch (e) {
        message.error('다이어트 요소를 수정하는데 실패했습니다.')
    } finally {
        await setUserDetail(props.userId)
    }
}

const searchInputVisible = ref(false)
const dietFactorTypeOptions = ref<any[]>([])
const selectDietFactorType = async (dietFactorType: any) => {
    const selectedOption = dietFactorTypeOptions.value.find(
        (it) => it.value === dietFactorType
    )
    userDetail.value.dietFactorTypeList.push({
        ...selectedOption,
    })
    await updateDietFactorType()
    searchInputVisible.value = false
}
const blurInput = () => {
    searchInputVisible.value = false
}
const searchDietFactorType = async (keyword: string) => {
    if (keyword != null && keyword.length < 2) {
        return
    }
    try {
        const response = await adminApi<ApiResponse<any>>(
            `/api/forme/diet/diet-factor/${keyword}`
        )
        const result = response.result || []
        dietFactorTypeOptions.value = result.map((it: any) => ({
            ...it,
            label: `${it.category} ${it.reason}`,
            value: it.dietFactorType,
        }))
    } catch {
        message.error('다이어트 요소를 불러오는데 실패했습니다.')
    }
}

const showInput = () => {
    searchInputVisible.value = true
}

const handleDietFactorClose = async (dietFactorType: string) => {
    userDetail.value.dietFactorTypeList =
        userDetail.value.dietFactorTypeList.filter(
            (it: any) => it.dietFactorType !== dietFactorType
        )
    await updateDietFactorType()
}

const mainWm = ref()
const subWm = ref([])

const hasAssignMessage = ref(false)
const assignMessageSendTime = ref<any>(null)

const initWellnessMaster = async (userId: number) => {
    const params = {
        userId: userId,
    }
    const response = await adminApi<ApiResponse<any>>(
        `/api/forme/diet/v1/wm-info?${stringify(params)}`
    )
    const mainWmResponse = response?.result?.mainWmList || []
    const mainWmList = mainWmResponse.map((wm: any) => {
        return {
            label: wm.wellnessMasterName,
            value: wm.wellnessMasterId,
        }
    })

    const subWmResponse = response?.result?.subWmList || []
    const subWmList = subWmResponse.map((wm: any) => {
        return {
            label: wm.wellnessMasterName,
            value: wm.wellnessMasterId,
        }
    })

    mainWm.value = mainWmList[0]
    subWm.value = subWmList

    hasAssignMessage.value = response?.result?.hasAssignMessage
    assignMessageSendTime.value = response?.result?.assignMessageSendTime
}

const { unreadCount, fetchUnreadCount, fetchQnaEnable, writeMessage } =
    useChatApp

const loadingPage = ref(false)

onMounted(async () => {
    loadingPage.value = true
    await getWeekList(props.userId)
    getWeeklyMessage(props.userId, filterParams.value.weekNumber || 1)
    filterParams.value.weekNumber = formeWeek.selectedWeek.weekNumber
    await setUserDetail(props.userId)
    await initWellnessMaster(props.userId)
    const enumTypeStore = useEnumTypeStore()
    enumTypeStore.dispatchEnums([
        'forme-category',
        'forme-wellness-master',
        'diet-name',
    ])
    loadingPage.value = false
    await fetchData()
    await fetchQnaEnable(props.userId)
    await fetchUnreadCount(props.userId)
})

const { setFormValues, form, fetchFormSpec } = globalFormState

const rules = {
    priority: [required],
    category: [required],
    type: [required],
    startDate: [required],
    endDate: [required],
    weight: [required],
    name: [required],
}

const selectMissionId = (value: any) => {
    setFormValues({
        category: value.category,
        type: value.type,
        name: value.name,
        nameTemplate: value.name,
        value: value.variable,
    })
}

const createSpec = ref<FormSpecification>({
    missionId: {
        label: '미션 ID',
        component: InputComponents.FormeMissionInput,
        select: selectMissionId,
    },
    userMissionId: {
        label: '유저 미션 ID',
        readonly: true,
    },
    category: {
        label: '카테고리(혈당/식단/수면 등)',
        component: InputComponents.EnumSelect,
        typeName: 'forme-category',
    },
    type: {
        label: '미션 타입(AI타입/커스텀)',
        value: '커스텀',
    },
    priority: {
        label: '우선순위',
        component: InputComponents.AInputNumber,
    },
    name: {
        label: '미션 이름(치환할 값은 {변수}로)',
    },
    nameTemplate: {
        hidden: true,
    },
    value: {
        label: '변수값',
    },
    weight: {
        label: '가중치 점수',
        component: InputComponents.AInputNumber,
    },
    startDate: {
        label: '미션 시작',
        component: InputComponents.ADatePicker,
        input: (value: any) => {
            if (value && form.value.endDate) {
                const diff = differenceInDays(form.value.endDate, value)
                setFormValues({
                    duration: diff,
                })
            }
        },
    },
    endDate: {
        label: '미션 종료',
        component: InputComponents.ADatePicker,
        input: (value) => {
            if (value && form.value.startDate) {
                const diff = differenceInDays(value, form.value.startDate)
                setFormValues({
                    duration: diff,
                })
            }
        },
    },
    duration: {
        label: '미션 기간',
        component: InputComponents.AInputNumber,
    },
    membershipEndDate: {
        label: '멤버십 종료 날짜',
        component: InputComponents.ADatePicker,
        readonly: true,
    },
    guide: {
        label: '미션 가이드',
        component: InputComponents.Tiptap,
    },
    hide: {
        label: '숨김 여부',
        component: InputComponents.BooleanInput,
    },
})

const updateSpec = ref<FormSpecification>({
    missionId: {
        label: '미션 ID',
        component: InputComponents.FormeMissionInput,
        select: selectMissionId,
    },
    userMissionId: {
        label: '유저 미션 ID',
        readonly: true,
    },
    category: {
        label: '카테고리(혈당/식단/수면 등)',
        component: InputComponents.EnumSelect,
        typeName: 'forme-category',
    },
    type: {
        label: '미션 타입(AI타입/커스텀(?))',
    },
    priority: {
        label: '우선순위',
        component: InputComponents.AInputNumber,
    },
    name: {
        label: '미션 이름',
        disabled: true,
    },
    nameTemplate: {
        hidden: true,
    },
    value: {
        label: '변수값',
    },
    weight: {
        label: '가중치 점수',
        component: InputComponents.AInputNumber,
        disabled: true,
    },
    startDate: {
        label: '미션 시작',
        component: InputComponents.ADatePicker,
        input: (value: any) => {
            if (value && form.value.endDate) {
                const diff = differenceInDays(form.value.endDate, value)
                setFormValues({
                    duration: diff,
                })
            }
        },
    },
    endDate: {
        label: '미션 종료',
        component: InputComponents.ADatePicker,
        input: (value) => {
            if (value && form.value.startDate) {
                const diff = differenceInDays(value, form.value.startDate)
                setFormValues({
                    duration: diff,
                })
            }
        },
    },
    duration: {
        label: '미션 기간',
        component: InputComponents.AInputNumber,
    },
    membershipEndDate: {
        label: '멤버십 종료 날짜',
        component: InputComponents.ADatePicker,
        readonly: true,
    },
    weekNumber: {
        label: '주차',
        component: InputComponents.WeekSelectInput,
        userId: props.userId,
        input: (value: any, resourceId: any) => {
            fetchFormSpec(
                `/api/forme/diet/mission/${props.userId}/${resourceId}?weekNumber=${value}`
            )
        },
    },
    guide: {
        label: '미션 가이드',
        component: InputComponents.Tiptap,
        disabled: () => isBefore(new Date(), formeWeek.selectedWeek.startDate),
        help: '작성한 가이드는 오늘 날짜 기준 사용자의 주차 미션에 적용됩니다.(주차를 따로 설정해도 적용되지 않습니다)',
    },
    feedbackScore: {
        hidden: true,
    },
    feedback: {
        label: '유저 미션 체크',
        readonly: true,
        type: InputType.PILLZ,
    },
    feedbackAnswer: {
        label: 'WM 미션 피드백',
        component: InputComponents.Tiptap,
        hidden: (record: Record<string, any>) => record.feedbackScore == null,
    },
    hide: {
        label: '숨김 여부',
        component: InputComponents.BooleanInput,
    },
})

const writeWmNote = debounce(async (userId: number, wmNote: string) => {
    await adminApi(`/api/forme/diet/wm/note/${userId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            note: wmNote,
        }),
    })
    message.success('WM 메모가 성공적으로 수정되었습니다.')
}, 500)

const onWriteWmNote = (value: string) => {
    userDetail.value.wmMemo = value
    writeWmNote(props.userId, value)
}

const writeNotionLink = debounce(async (userId: number, notionLink: string) => {
    await adminApi(`/api/forme/diet/wm/notion/${userId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            notion: notionLink,
        }),
    })
    message.success('노션 링크가 성공적으로 수정되었습니다.')
}, 500)

const onWriteNotionLink = (value: string) => {
    userDetail.value.notionLink = value
    writeNotionLink(props.userId, value)
}

const weeklyMessage = ref('')

const getWeeklyMessage = async (userId: number, weekNumber: number) => {
    const params = {
        weekNumber: weekNumber,
        formeMessageType: 'WEEKLY',
    }
    const response = await adminApi<ApiResponse<string>>(
        `/api/forme/diet/message/${userId}?${stringify(params)}`
    )
    weeklyMessage.value = response.result || ''
}

const fetchData = async () => {
    await setUserDetail(props.userId)
    fetchTable()
    getWeeklyMessage(props.userId, filterParams.value.weekNumber || 1)
}

const onUpdateCategory = async (value: any) => {
    filterParams.value.category = value
    fetchTable()
}

const selectedTable = ref<string[]>(['mission'])

const fetchTable = async () => {
    if (selectedTable.value.includes('mission')) {
        await table.value.fetchData()
    } else {
        await dataTable.value.fetchData()
    }
}

const reoderDietFactorTypeList = async (dietFactorTypeList: any[]) => {
    await adminApi(`/api/forme/diet/reorder/find-factor/${props.userId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dietFactorTypeList),
    })
}

const onReorderDietFactorTypeList = async () => {
    await reoderDietFactorTypeList(
        userDetail.value.dietFactorTypeList.map((it: any) => it.dietFactorType)
    )
}

const completeMission = async () => {
    loading.value = true
    await adminApi(
        `/api/forme/diet/mission/complete/${props.userId}?weekNumber=${filterParams.value.weekNumber}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
    loading.value = false
}

const createMissionByDietFactor = async () => {
    loading.value = true
    try {
        await adminApi(
            `/api/forme/diet/mission-by-diet-factor/${props.userId}`,
            {
                method: 'POST',
            }
        )
        await setUserDetail(props.userId)
    } catch (e) {
        console.error(e)
        findFactorFail.value = true
    } finally {
        loading.value = false
    }
}

const completeFindFactor = async () => {
    loading.value = true
    await adminApi(`/api/forme/diet/complete/find-factor/${props.userId}`, {
        method: 'POST',
    })
    setUserDetail(props.userId)
    loading.value = false
}

const isFuture = computed(() =>
    isBefore(new Date(), formeWeek.selectedWeek.startDate || new Date())
)

const collapseKey = ref<Array<number>>([])

const saveUserMasters = async () => {
    const url = `/api/forme/diet/v1/wm-assign`
    const parsedMainWmId = parseMainWmId(mainWm.value)
    const parsedSubWmId = parseSubWmId(subWm.value)
    const payload = {
        userId: props.userId,
        mainWmIdList: [parsedMainWmId],
        subWmIdList: parsedSubWmId,
    }
    await executePostAPI(url, payload)
}

const parseMainWmId = (value: any) => {
    if (Object.hasOwn(value, 'value')) {
        return value.value
    }
    return value
}

const parseSubWmId = (value: any) => {
    if (value?.length > 0) {
        const obj = value[0]
        if (Object.hasOwn(obj, 'value')) {
            return value.map((v: any) => v.value)
        }
        return value
    }
    return []
}

const sendWMAssignMessage = async () => {
    const url = `/api/forme/diet/v1/wm-assign-message`
    const payload = {
        userId: props.userId,
    }
    await executePostAPI(url, payload)
    hasAssignMessage.value = true
    assignMessageSendTime.value = getCurrentTime()
}

const toggleSatisfactionSurvey = async (surveyNumber: number) => {
    const url = `/api/forme/diet/membership/toggle-survey`
    const payload = {
        userId: props.userId,
        surveyNumber: surveyNumber,
    }
    await executePostAPI(url, payload)
}

const executePostAPI = async (url: string, payload: any) => {
    loading.value = true
    await adminApi(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    })
    loading.value = false
}

function getCurrentTime() {
    const now = new Date()
    const year = now.getFullYear()
    const month = String(now.getMonth() + 1).padStart(2, '0')
    const day = String(now.getDate()).padStart(2, '0')
    const hours = String(now.getHours()).padStart(2, '0')
    const minutes = String(now.getMinutes()).padStart(2, '0')
    const seconds = String(now.getSeconds()).padStart(2, '0')
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

const editableTbg = ref(false)
const changeTbgMin = (value: any) => {
    userDetail.value.tbgMin = value
}
const changeTbgMax = (value: any) => {
    userDetail.value.tbgMax = value
}
const saveTbg = async (e: any) => {
    e.preventDefault()
    const url = '/api/forme/diet/v1/tbg'
    const payload = {
        userId: userDetail.value.userId,
        tbgMin: userDetail.value.tbgMin,
        tbgMax: userDetail.value.tbgMax,
    }
    await executePostAPI(url, payload)
    editTbg()
}
const editTbg = () => {
    editableTbg.value = !editableTbg.value
}

const cpfEditable = ref(false)
const carbohydrateGram = ref(0)
const proteinGram = ref(0)
const proteinWeightNx = ref(0)
const fatGram = ref(0)
const selectDiet = (value: any) => {
    userDetail.value.selectedDiet = value
}
const calCpfValues = () => {
    carbohydrateGram.value = calGram(userDetail.value.targetCarbohydrate, 4)
    proteinGram.value = calGram(userDetail.value.targetProtein, 4)
    proteinWeightNx.value = calWeightNx(userDetail.value.targetProtein)
    fatGram.value = calGram(userDetail.value.targetFat, 9)
}
const openMealModal = () => {
    calCpfValues()
    cpfEditable.value = true
}
const changeCalorie = (value: any) => {
    userDetail.value.targetCal = value
    calCpfValues()
}
const changeCarbohydrate = (value: any) => {
    userDetail.value.targetCarbohydrate = value
    carbohydrateGram.value = calGram(value, 4)
}
const changeProtein = (value: any) => {
    userDetail.value.targetProtein = value
    proteinGram.value = calGram(value, 4)
    proteinWeightNx.value = calWeightNx(value)
}
const changeFat = (value: any) => {
    userDetail.value.targetFat = value
    fatGram.value = calGram(value, 9)
}
const calGram = (targetValue: number, value: number) => {
    const rate = targetValue / 100
    return Math.round((userDetail.value.targetCal * rate) / value)
}
const calWeightNx = (value: number) => {
    const gram = calGram(value, 4)
    return (gram / userDetail.value.weight).toFixed(2)
}
const saveCpf = async () => {
    if (checkCpf()) {
        alert('탄단지 합계는 100 이어야 합니다.')
        return
    }
    const url = '/api/forme/diet/v1/cpf'
    const payload = {
        userId: userDetail.value.userId,
        selectedDiet: userDetail.value.selectedDiet,
        calorie: userDetail.value.targetCal,
        carbohydrate: userDetail.value.targetCarbohydrate,
        protein: userDetail.value.targetProtein,
        fat: userDetail.value.targetFat,
    }
    await executePostAPI(url, payload)
    cpfEditable.value = false
}
const checkCpf = () => {
    const sum =
        userDetail.value.targetCarbohydrate +
        userDetail.value.targetProtein +
        userDetail.value.targetFat
    return sum != 100
}

const tableMenu = ref<MenuProps['items']>([
    {
        key: 'mission',
        label: '미션',
        title: '미션',
    },
    {
        key: 'data',
        label: '데이터',
        title: '데이터',
    },
    {
        key: 'findFactor',
        label: '범인 찾기',
        title: '범인 찾기',
    },
])

const writeWeeklyMessage = async (userId: number) => {
    loading.value = true
    await writeMessage(userId, 'WEEKLY', 'HTML', weeklyMessage.value)
    await setUserDetail(userId)
    loading.value = false
}

const findFactorReportColumns = computed(() => {
    const selectedWeek = formeWeek.selectedWeek
    const dateTileList =
        selectedWeek != null
            ? getDateList(selectedWeek.startDate, selectedWeek.endDate).map(
                  (it, index) => ({
                      title: format(it, 'M.d(EEE)', { locale: ko }),
                      dataIndex: `day${index + 1}`,
                      align: 'center',
                      customRender: (column: any) => {
                          return (
                              <Button type={'primary'}>
                                  <a
                                      href={`https://solution.pillyze.com/forme/report/${
                                          props.userId
                                      }?reportDate=${format(it, 'yyyy-MM-dd')}`}
                                      target="_blank"
                                  >
                                      조회
                                  </a>
                              </Button>
                          )
                      },
                  })
              )
            : []
    return [...dateTileList]
})

const findFactorReportDatasource = [
    {
        day1: '조회',
        day2: '조회',
        day3: '조회',
        day4: '조회',
        day5: '조회',
        day6: '조회',
        day7: '조회',
    },
]

return (_ctx: any,_cache: any) => {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_auto_complete = _resolveComponent("a-auto-complete")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_collapse_panel = _resolveComponent("a-collapse-panel")!
  const _component_a_collapse = _resolveComponent("a-collapse")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_flex = _resolveComponent("a-flex")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_spin, {
      tip: "Loading...",
      spinning: loadingPage.value
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_flex, { vertical: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_card, null, {
              title: _withCtx(() => [
                _createVNode(_component_a_typography_title, {
                  level: 4,
                  style: {"display":"inline","margin-right":"10px"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(userDetail.value.userName), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_typography_text, { type: "secondary" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(userDetail.value.age) + "세 / " + _toDisplayString(userDetail.value.gender == 'MALE' ? '남' : '여') + " / " + _toDisplayString(userDetail.value.pregnant ? '임신' : '') + " ID " + _toDisplayString(userDetail.value.userId) + " / " + _toDisplayString(userDetail.value.userAppVersion) + " (최신:" + _toDisplayString(userDetail.value.latestAppVersion) + ") / " + _toDisplayString(userDetail.value.osType) + " / " + _toDisplayString(`식단 ${userDetail.value.mealCount || 0}회 입력`) + " ", 1),
                    (userDetail.value.sensorCount)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 0,
                          to: `${__props.userId}/cgmDevice`
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(`/ 센서 ${userDetail.value.sensorCount || 0}개 사용`), 1)
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(userDetail.value.usedVcgm ? '/ vCGM써 봄' : ''), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_space, { style: {"float":"right"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: `/chat/${userDetail.value.userId}`
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(SendOutlined)),
                        _cache[15] || (_cache[15] = _createTextVNode(" 메세지 ")),
                        _createVNode(_component_a_badge, { count: _unref(unreadCount) }, null, 8, ["count"])
                      ]),
                      _: 1
                    }, 8, ["to"])
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_a_descriptions, { column: 4 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_descriptions_item, { label: "키" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(userDetail.value.height), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_descriptions_item, { label: "시작 체중" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(userDetail.value.startWeight) + "kg (BMI " + _toDisplayString(Math.round(userDetail.value.startBmi) || 0) + ") ", 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_descriptions_item, { label: "현재 체중" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(userDetail.value.weight) + "kg (BMI " + _toDisplayString(Math.round(userDetail.value.bmi) || 0) + ") ", 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_descriptions_item, { label: "목표 체중" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(userDetail.value.targetWeight) + "kg (BMI " + _toDisplayString(Math.round(userDetail.value.targetBmi) || 0) + ") ", 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_descriptions_item, { label: "건강고민" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(userDetail.value.higList?.join(', ')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_descriptions_item, { label: "건강 검진" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(userDetail.value.mcuList?.join(', ')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_descriptions_item, { label: "복용약물" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(userDetail.value.drugList?.join(', ')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_descriptions_item, { label: "알러지" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(userDetail.value.allergyList?.join(', ')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_descriptions_item, { label: "기저질환" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(userDetail.value.diseaseList?.join(', ')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_descriptions_item, { label: "가족력" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(userDetail.value.familyDiseaseList?.join(', ')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_descriptions_item, { label: "체형 고민" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(userDetail.value.bodyShapeList?.join(', ')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_descriptions_item, { label: "비만 가족력" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(userDetail.value.familyObesityList?.join(', ')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_descriptions_item, { label: "목표 혈당" }, {
                      default: _withCtx(() => [
                        (!editableTbg.value)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                              _createElementVNode("span", null, _toDisplayString(userDetail.value.tbgMin), 1),
                              _cache[16] || (_cache[16] = _createElementVNode("span", null, " / ", -1)),
                              _createElementVNode("span", null, _toDisplayString(userDetail.value.tbgMax), 1),
                              _createElementVNode("img", {
                                alt: "ic-edit-fill",
                                class: "ic-edit",
                                src: "https://cdn.pillyze.io/assets/d3bdf029a431f6bdd6515aed155cf991/ic/ic_edit_fill.webp",
                                onClick: editTbg
                              })
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                              _createVNode(_component_a_input_number, {
                                class: "tbg-input",
                                value: userDetail.value.tbgMin,
                                onChange: changeTbgMin
                              }, null, 8, ["value"]),
                              _cache[18] || (_cache[18] = _createElementVNode("span", null, " / ", -1)),
                              _createVNode(_component_a_input_number, {
                                class: "tbg-input",
                                value: userDetail.value.tbgMax,
                                onChange: changeTbgMax
                              }, null, 8, ["value"]),
                              _createVNode(_component_a_button, {
                                type: "primary",
                                class: "tbg-btn",
                                onClick: saveTbg
                              }, {
                                default: _withCtx(() => _cache[17] || (_cache[17] = [
                                  _createTextVNode(" 확인 ")
                                ])),
                                _: 1
                              })
                            ]))
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_descriptions_item, { label: "목표 칼로리" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                          _createElementVNode("span", null, _toDisplayString(userDetail.value.targetCal) + "kcal", 1),
                          _createElementVNode("img", {
                            alt: "ic-edit-fill",
                            class: "ic-edit",
                            src: "https://cdn.pillyze.io/assets/d3bdf029a431f6bdd6515aed155cf991/ic/ic_edit_fill.webp",
                            onClick: openMealModal
                          })
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_descriptions_item, { label: "목표 식단" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("span", null, _toDisplayString(userDetail.value.selectedDiet), 1),
                          _createElementVNode("img", {
                            alt: "ic-edit-fill",
                            class: "ic-edit",
                            src: "https://cdn.pillyze.io/assets/d3bdf029a431f6bdd6515aed155cf991/ic/ic_edit_fill.webp",
                            onClick: openMealModal
                          })
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_descriptions_item, { label: "목표 탄단지 비율" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("span", null, _toDisplayString(userDetail.value.targetCarbohydrate) + ":" + _toDisplayString(userDetail.value.targetProtein) + ":" + _toDisplayString(userDetail.value.targetFat), 1),
                          _createElementVNode("img", {
                            alt: "ic-edit-fill",
                            class: "ic-edit",
                            src: "https://cdn.pillyze.io/assets/d3bdf029a431f6bdd6515aed155cf991/ic/ic_edit_fill.webp",
                            onClick: openMealModal
                          })
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form, {
                  "label-col": { span: 2 },
                  "label-align": 'left'
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { label: "범인 타입" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_space, { wrap: "" }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Draggable), {
                              modelValue: userDetail.value.dietFactorTypeList,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((userDetail.value.dietFactorTypeList) = $event)),
                              onChange: onReorderDietFactorTypeList,
                              "item-key": "dietFactorType"
                            }, {
                              item: _withCtx(({ element }) => [
                                (_openBlock(), _createBlock(_component_a_tag, {
                                  key: element.dietFactorType,
                                  onClose: ($event: any) => (
                                            handleDietFactorClose(
                                                element.dietFactorType
                                            )
                                        ),
                                  closable: ""
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_typography_text, { strong: "" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(element.category + ' '), 1)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_a_typography_text, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(element.reason), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1032, ["onClose"]))
                              ]),
                              _: 1
                            }, 8, ["modelValue"]),
                            (searchInputVisible.value)
                              ? (_openBlock(), _createBlock(_component_a_auto_complete, {
                                  key: 0,
                                  style: {"width":"200px"},
                                  options: dietFactorTypeOptions.value,
                                  onSelect: selectDietFactorType,
                                  onSearch: searchDietFactorType,
                                  onBlur: blurInput
                                }, null, 8, ["options"]))
                              : (
                                    userDetail.value.dietFactorTypeList &&
                                    userDetail.value.dietFactorTypeList.length > 0
                                )
                                ? (_openBlock(), _createBlock(_component_a_tag, {
                                    key: 1,
                                    style: {"background":"#fff","border-style":"dashed"},
                                    onClick: showInput
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_unref(PlusOutlined)),
                                      _cache[19] || (_cache[19] = _createTextVNode(" 팩터 추가 "))
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_space, {
                          style: {"float":"right"},
                          direction: "vertical"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_6, [
                              (findFactorFail.value)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_7, " 범인 찾기 실패 "))
                                : _createCommentVNode("", true),
                              (userDetail.value.findFactorAt)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_8, " 범인찾기 날짜: " + _toDisplayString(userDetail.value.findFactorAt != null
                                            ? _unref(formatLocalDateTime)(
                                                  userDetail.value.findFactorAt
                                              )
                                            : ' '), 1))
                                : _createCommentVNode("", true),
                              _createVNode(_component_a_button, {
                                onClick: createMissionByDietFactor,
                                loading: loading.value,
                                disabled: 
                                        !userDetail.value.findFactorEnable ||
                                        userDetail.value.findFactorAt
                                    
                              }, {
                                default: _withCtx(() => _cache[20] || (_cache[20] = [
                                  _createTextVNode(" 범인 찾기 실행 ")
                                ])),
                                _: 1
                              }, 8, ["loading", "disabled"])
                            ]),
                            _createElementVNode("div", _hoisted_9, [
                              (userDetail.value.findFactorCompletedAt)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_10, " 전송 시간: " + _toDisplayString(userDetail.value.findFactorCompletedAt != null
                                            ? _unref(formatLocalDateTime)(
                                                  userDetail.value.findFactorCompletedAt
                                              )
                                            : ' '), 1))
                                : _createCommentVNode("", true),
                              _createVNode(_component_a_button, {
                                onClick: completeFindFactor,
                                type: "primary",
                                loading: loading.value,
                                disabled: userDetail.value.findFactorCompletedAt
                              }, {
                                default: _withCtx(() => _cache[21] || (_cache[21] = [
                                  _createTextVNode(" 범인찾기 리포트 알림톡 전송 완료 ")
                                ])),
                                _: 1
                              }, 8, ["loading", "disabled"])
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_form_item, {
                      label: "WM 메모",
                      help: '웰니스마스터만 확인하는 내용으로, 유저에게 공개되지 않아요.'
                    }, {
                      default: _withCtx(() => [
                        _createVNode(Tiptap, {
                          value: userDetail.value.wmMemo,
                          "onUpdate:value": onWriteWmNote
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_collapse, {
                      "active-key": collapseKey.value,
                      "onUpdate:activeKey": _cache[1] || (_cache[1] = ($event: any) => ((collapseKey).value = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_collapse_panel, {
                          key: "1",
                          header: "더 보기"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form_item, { label: "문진표" }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(userDetail.value.wellnessSurvey?.answer), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_form_item, { label: "검진결과 공유" }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(userDetail.value.wellnessSurvey?.healthCheckup), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_form_item, { label: "다이어트 경험" }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(userDetail.value.wellnessSurvey
                                            ?.dietExperience), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_form_item, { label: "다이어트 목표" }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(userDetail.value.wellnessSurvey?.reasonForDiet), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_form_item, { label: "셀프 원인 분석" }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(userDetail.value.wellnessSurvey?.guessReason), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_form_item, { label: "노션 링크" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_input, {
                                  value: userDetail.value.notionLink,
                                  "onUpdate:value": onWriteNotionLink
                                }, {
                                  addonAfter: _withCtx(() => [
                                    _createElementVNode("a", {
                                      href: userDetail.value.notionLink,
                                      target: "_blank",
                                      rel: "noopener noreferrer"
                                    }, [
                                      _createVNode(_unref(LinkOutlined))
                                    ], 8, _hoisted_11)
                                  ]),
                                  _: 1
                                }, 8, ["value"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["active-key"]),
                    _createVNode(_component_a_form_item, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_12, [
                          _createVNode(_component_a_checkbox, {
                            checked: userDetail.value.survey1,
                            "onUpdate:checked": _cache[2] || (_cache[2] = ($event: any) => ((userDetail.value.survey1) = $event)),
                            onChange: _cache[3] || (_cache[3] = ($event: any) => (toggleSatisfactionSurvey(1)))
                          }, {
                            default: _withCtx(() => _cache[22] || (_cache[22] = [
                              _createTextVNode("4주 만족도 조사 완료 ")
                            ])),
                            _: 1
                          }, 8, ["checked"]),
                          _createVNode(_component_a_checkbox, {
                            checked: userDetail.value.survey2,
                            "onUpdate:checked": _cache[4] || (_cache[4] = ($event: any) => ((userDetail.value.survey2) = $event)),
                            onChange: _cache[5] || (_cache[5] = ($event: any) => (toggleSatisfactionSurvey(2)))
                          }, {
                            default: _withCtx(() => _cache[23] || (_cache[23] = [
                              _createTextVNode("8주 만족도 조사 완료 ")
                            ])),
                            _: 1
                          }, 8, ["checked"]),
                          _createVNode(_component_a_checkbox, {
                            checked: userDetail.value.survey3,
                            "onUpdate:checked": _cache[6] || (_cache[6] = ($event: any) => ((userDetail.value.survey3) = $event)),
                            onChange: _cache[7] || (_cache[7] = ($event: any) => (toggleSatisfactionSurvey(3)))
                          }, {
                            default: _withCtx(() => _cache[24] || (_cache[24] = [
                              _createTextVNode("12주 만족도 조사 완료 ")
                            ])),
                            _: 1
                          }, 8, ["checked"])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_form, { style: {"margin-top":"48px"} }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, { style: {"display":"flex"} }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(_component_a_form_item, {
                        class: "wm-form-item",
                        label: "메인 WM"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(EnumSelect, {
                            style: {"width":"auto","margin-right":"10px"},
                            value: mainWm.value,
                            "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((mainWm).value = $event)),
                            "type-name": 'forme-wellness-master'
                          }, null, 8, ["value"]),
                          _createVNode(_component_a_button, {
                            class: "wm-btn-save",
                            type: "primary",
                            loading: loading.value,
                            onClick: saveUserMasters
                          }, {
                            default: _withCtx(() => _cache[25] || (_cache[25] = [
                              _createTextVNode(" 저장 ")
                            ])),
                            _: 1
                          }, 8, ["loading"]),
                          _createVNode(_component_a_button, {
                            type: "primary",
                            class: "wm-btn-assign",
                            disabled: hasAssignMessage.value,
                            loading: loading.value,
                            onClick: sendWMAssignMessage
                          }, {
                            default: _withCtx(() => [
                              _cache[26] || (_cache[26] = _createTextVNode(" WM 배정 알림톡 전송 ")),
                              (assignMessageSendTime.value)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(assignMessageSendTime.value), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["disabled", "loading"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_form_item, {
                        class: "wm-form-item",
                        label: "서브 WM"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(EnumSelect, {
                            style: {"width":"auto","margin-right":"10px"},
                            value: subWm.value,
                            "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((subWm).value = $event)),
                            "type-name": 'forme-wellness-master',
                            mode: 'multiple'
                          }, null, 8, ["value"]),
                          _createVNode(_component_a_button, {
                            class: "wm-btn-save",
                            type: "primary",
                            loading: loading.value,
                            onClick: saveUserMasters
                          }, {
                            default: _withCtx(() => _cache[27] || (_cache[27] = [
                              _createTextVNode(" 저장 ")
                            ])),
                            _: 1
                          }, 8, ["loading"])
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio_group, {
                      value: filterParams.value.category,
                      "onUpdate:value": onUpdateCategory
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_radio_button, null, {
                          default: _withCtx(() => _cache[28] || (_cache[28] = [
                            _createTextVNode("전체")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_a_radio_button, { value: "체중" }, {
                          default: _withCtx(() => _cache[29] || (_cache[29] = [
                            _createTextVNode("체중")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_a_radio_button, { value: "혈당" }, {
                          default: _withCtx(() => _cache[30] || (_cache[30] = [
                            _createTextVNode("혈당")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_a_radio_button, { value: "식단" }, {
                          default: _withCtx(() => _cache[31] || (_cache[31] = [
                            _createTextVNode("식단")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_a_radio_button, { value: "활동" }, {
                          default: _withCtx(() => _cache[32] || (_cache[32] = [
                            _createTextVNode("활동")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_a_radio_button, { value: "수면" }, {
                          default: _withCtx(() => _cache[33] || (_cache[33] = [
                            _createTextVNode("수면")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(WeekSelectInput, {
                      "user-id": __props.userId,
                      value: filterParams.value.weekNumber,
                      "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((filterParams.value.weekNumber) = $event)),
                      onAfterUpdate: fetchData
                    }, null, 8, ["user-id", "value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, {
                  label: "WM 위클리 하이라이트",
                  help: '유저에게 전달하는 메시지로, [메시지 전달] 버튼을 누르면 앱으로 전달돼요.'
                }, {
                  default: _withCtx(() => [
                    _createVNode(Tiptap, {
                      value: weeklyMessage.value,
                      "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((weeklyMessage).value = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("span", _hoisted_16, " 최근 전송 시간: " + _toDisplayString(_unref(formatLocalDateTime)(userDetail.value.wmMessageWriteAt)), 1),
                      _createVNode(_component_a_button, {
                        onClick: _cache[12] || (_cache[12] = () => writeWeeklyMessage(__props.userId)),
                        loading: loading.value,
                        type: "primary",
                        disabled: userDetail.value.wmMessageWriteAt || isFuture.value
                      }, {
                        default: _withCtx(() => _cache[34] || (_cache[34] = [
                          _createTextVNode("메세지 전송 ")
                        ])),
                        _: 1
                      }, 8, ["loading", "disabled"])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_menu, {
              class: "pt-10 pb-10",
              "selected-keys": selectedTable.value,
              "onUpdate:selectedKeys": _cache[13] || (_cache[13] = ($event: any) => ((selectedTable).value = $event)),
              mode: "horizontal",
              items: tableMenu.value,
              onSelect: fetchTable
            }, null, 8, ["selected-keys", "items"]),
            _withDirectives(_createVNode(NestedResourceTable, {
              ref_key: "table",
              ref: table,
              title: "미션",
              "create-modal-title": "커스텀 미션 추가",
              params: filterParams.value,
              uri: `/api/forme/diet/mission/${__props.userId}`,
              columns: columns.value,
              "create-spec": createSpec.value,
              "create-rules": rules,
              "update-spec": updateSpec.value,
              "update-rules": rules,
              "primary-key": "userMissionId",
              scroll: { x: 'max-content' }
            }, {
              toolbar: _withCtx(() => [
                (userDetail.value.weekCompleteAt)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_17, "최근 전송 시간: " + _toDisplayString(_unref(formatLocalDateTime)(userDetail.value.weekCompleteAt)), 1))
                  : _createCommentVNode("", true),
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: completeMission,
                  loading: loading.value,
                  disabled: userDetail.value.weekCompleteAt || isFuture.value
                }, {
                  default: _withCtx(() => _cache[35] || (_cache[35] = [
                    _createTextVNode("미션 알림톡 전송 ")
                  ])),
                  _: 1
                }, 8, ["loading", "disabled"])
              ]),
              _: 1
            }, 8, ["params", "uri", "columns", "create-spec", "update-spec"]), [
              [_vShow, selectedTable.value.includes('mission')]
            ]),
            _withDirectives(_createVNode(NestedResourceTable, {
              ref_key: "dataTable",
              ref: dataTable,
              title: "데이터",
              uri: `/api/forme/diet/monitor/${__props.userId}`,
              columns: monitorColumns.value,
              params: filterParams.value,
              scroll: { x: 'max-content' },
              "load-on-mount": false
            }, null, 8, ["uri", "columns", "params"]), [
              [_vShow, selectedTable.value.includes('data')]
            ]),
            _withDirectives(_createVNode(_component_a_table, {
              "data-source": findFactorReportDatasource,
              columns: findFactorReportColumns.value
            }, null, 8, ["columns"]), [
              [_vShow, selectedTable.value.includes('findFactor')]
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["spinning"]),
    _createVNode(_component_a_modal, {
      class: "modal-meal-info",
      title: "식단 목표 수정",
      open: cpfEditable.value,
      "onUpdate:open": _cache[14] || (_cache[14] = ($event: any) => ((cpfEditable).value = $event)),
      width: "70%",
      "cancel-text": "취소",
      "ok-text": "수정",
      onOk: saveCpf
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_descriptions, {
          layout: 'horizontal',
          column: 1,
          bordered: true
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_descriptions_item, { label: "BMR" }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(Math.round(userDetail.value.bmr)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_a_descriptions_item, { label: "목표 식단" }, {
              default: _withCtx(() => [
                _createVNode(EnumSelect, {
                  "type-name": "diet-name",
                  value: userDetail.value.selectedDiet,
                  onSelect: selectDiet
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_descriptions_item, { label: "목표 칼로리" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(_component_a_input_number, {
                    class: "modal-input",
                    value: userDetail.value.targetCal,
                    onChange: changeCalorie
                  }, null, 8, ["value"]),
                  _cache[36] || (_cache[36] = _createElementVNode("span", null, " kcal", -1))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_descriptions_item, { label: "목표 탄단지 비율" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("section", _hoisted_20, [
                    _createVNode(_component_a_input_number, {
                      class: "modal-input",
                      min: "0",
                      max: "100",
                      value: userDetail.value.targetCarbohydrate,
                      onChange: changeCarbohydrate
                    }, null, 8, ["value"]),
                    _createElementVNode("span", _hoisted_21, _toDisplayString(carbohydrateGram.value) + "g ", 1)
                  ]),
                  _createElementVNode("section", _hoisted_22, [
                    _createVNode(_component_a_input_number, {
                      class: "modal-input",
                      min: "0",
                      max: "100",
                      value: userDetail.value.targetProtein,
                      onChange: changeProtein
                    }, null, 8, ["value"]),
                    _createElementVNode("span", _hoisted_23, _toDisplayString(proteinGram.value) + "g ", 1),
                    _createElementVNode("span", _hoisted_24, " 체중 " + _toDisplayString(proteinWeightNx.value) + "배 ", 1)
                  ]),
                  _createElementVNode("section", _hoisted_25, [
                    _createVNode(_component_a_input_number, {
                      class: "modal-input",
                      min: "0",
                      max: "100",
                      value: userDetail.value.targetFat,
                      onChange: changeFat
                    }, null, 8, ["value"]),
                    _createElementVNode("span", _hoisted_26, _toDisplayString(fatGram.value) + "g ", 1)
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["open"]),
    _createVNode(_component_router_view)
  ], 64))
}
}

})
<template>
    <ResourceTable
        title="이벤트 사전알림 신청"
        uri="/api/v1/event-user-alarms"
        :filter-spec="filterSpec"
        :create-rules="rules"
        :update-rules="rules"
        :scroll="{ x: 1400, y: 650 }"
        :columns="columns"
        :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
        }"
        exportable
        load-on-mount
    >
        <template #actions="{ uri, search }">
            <bulk-action-modal
                class="modal__button"
                @after-submit="resetSelectedRows(search)"
                :resource-ids="selectedRowKeys"
                :uri="`${uri}/notify`"
                :input-spec="notificationSpec"
                :rules="notificationRules"
                label="이벤트 알림 발송"
                :disabled="!selected()"
            />
            <bulk-action-modal
                class="modal__button"
                @after-submit="resetSelectedRows(search)"
                :resource-ids="selectedRowKeys"
                :uri="`${uri}/push`"
                :input-spec="pushSpec"
                :rules="notificationRules"
                label="이벤트 알림 발송(알림만)"
                :disabled="!selected()"
            />
        </template>
        <template #customAction="{ record }">
            <a-image
                v-for="(url, index) in record.proofImageUrl"
                :src="url"
                :key="index"
            />
        </template>
    </ResourceTable>
</template>

<script lang="ts">
import {
    formatEnum,
    formatLocalDateTime,
    formatLocaleNumber,
} from '@/util/formmater'
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent, ref, Ref } from 'vue'
import { required } from '@/util/input-validation'
import { stringify } from 'qs'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import BulkActionModal from '@/components/modal/BulkActionModal.vue'
import { InputComponents } from '@/components/InputMapper.vue'
import { useEnumTypeStore } from '@/store/enumType'

export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Event',
    components: {
        ResourceTable,
        BulkActionModal,
    },
    setup() {
        const selectedRowKeys: Ref<Array<number>> = ref([])
        const selectedImageUrl = ref('')
        const loading = ref(false)
        const resetSelectedRows = (search: () => void) => {
            selectedRowKeys.value = []
            search()
        }
        const selected = () => {
            return selectedRowKeys.value.length > 0
        }

        return {
            selectedRowKeys,
            selectedImageUrl,
            loading,
            resetSelectedRows,
            selected,
        }
    },
    methods: {
        onSelectChange(selectedRowKeys: Array<number>) {
            this.selectedRowKeys = selectedRowKeys
        },
        async updateEventUser(param: string, uri: string, search: () => void) {
            if (this.selectedRowKeys.length > 0 && this.loading === false) {
                this.loading = true
                try {
                    await adminApi<ApiResponse<void>>(`${uri}?${param}`, {
                        method: 'PATCH',
                    })
                } catch (err) {
                    console.error(err)
                    throw err
                } finally {
                    setTimeout(() => {
                        this.loading = false
                        this.selectedRowKeys = []
                        search()
                    }, 1000)
                }
            }
        },
    },
    data() {
        return {
            filterSpec: {
                eventId: {
                    label: '이벤트 ID',
                    value: null,
                    component: 'AInputNumber',
                },
                userId: {
                    label: '사용자 ID',
                    value: [],
                    component: 'AutoCompleteInput',
                    uri: '/api/user/id',
                    mode: 'multiple',
                },
                userIdCsv: {
                    label: '사용자 ID(,로 구분)',
                    value: null,
                    description:
                        '다른 사용자 ID와 동시 사용하지말것, 검증을 하지 않음으로 주의요망',
                },
            },
            notificationSpec: {
                title: {
                    label: '제목',
                    value: null,
                    component: InputComponents.TemplateInput,
                },
                message: {
                    label: '메시지',
                    value: null,
                    component: InputComponents.TemplateInput,
                },
                body: {
                    label: '내용',
                    value: null,
                    component: InputComponents.TemplateInput,
                },
                detailBody: {
                    label: '상세내용',
                    value: null,
                    component: InputComponents.TemplateInput,
                },
                imageUrl: {
                    label: '이미지 URL',
                    value: null,
                    component: InputComponents.ImageInput,
                },
                detailLink: {
                    label: '상세링크',
                    value: null,
                },
            },
            pushSpec: {
                title: {
                    label: '제목',
                    value: null,
                    component: InputComponents.TemplateInput,
                },
                message: {
                    label: '메시지',
                    value: null,
                    component: InputComponents.TemplateInput,
                },
                body: {
                    label: '내용',
                    value: null,
                    component: InputComponents.TemplateInput,
                },
                imageUrl: {
                    label: '이미지 URL',
                    value: null,
                    component: InputComponents.ImageInput,
                },
                link: {
                    label: '링크',
                    value: null,
                },
            },
            columns: [
                {
                    title: '이벤트ID',
                    dataIndex: 'eventId',
                    width: 50,
                },
                {
                    title: '사용자 ID',
                    dataIndex: 'userId',
                    width: 70,
                },
                {
                    title: '파라미터',
                    dataIndex: 'param',
                    width: 50,
                },
                {
                    title: '휴대폰',
                    dataIndex: 'phone',
                    width: 50,
                },
                {
                    title: '이름',
                    dataIndex: 'name',
                    width: 50,
                },
            ],
            rules: {
                name: [required],
                title: [required],
                description: [required],
                serialNo: [required],
                eventUrl: [required],
                shareImageUrl: [required],
                eventType: [required],
                visible: [required],
                webVisible: [required],
                manager: [required],
                startAt: [required],
                endAt: [required],
            },
            notificationRules: {
                title: [required],
            },
            pushRules: {
                title: [required],
                link: [required],
            },
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums(['event-type'])
        enumTypeStore.dispatchEnums(['event-fee-status'])
    },
})
</script>

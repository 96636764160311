<template>
    <modal-button
        class="modal__button"
        ref="modalButtonRef"
        v-bind="$props"
        :rules="formRules"
        @submit="onSubmit"
        @after-show="initForm"
    >
        <template #button> 답변 </template>
    </modal-button>
</template>

<script lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import {
    FormSpecification,
    InputComponents,
    InputSpecification,
    InputType,
    SelectInputMode,
} from '@/components/InputMapper.vue'
import { required } from '@/util/input-validation'
import ModalButton from './ModalButton.vue'
import { defineComponent, ref } from 'vue'
import globalFormState from '@/components/globalFormState'

export type UserFeedbackTopic =
    | 'ACCOUNT_DELETE'
    | 'PRODUCT_NOT_FOUND'
    | 'INGREDIENT_NOT_FOUND'
    | 'ALLERGY'
    | 'MCU_REPORT_ERROR'
    | 'DRUG'
    | 'MARKET_REVIEW'
    | 'ANALYSIS_IMPOSSIBLE_PRODUCTS'
    | 'DISEASE'
    | 'NUTRIENT_CORRECTION'
    | 'PRODUCT_CORRECTION'
    | 'PRODUCT_QUESTION'
    | 'PRODUCT_REVIEW_BLIND'
    | 'FOOD_NOT_FOUND'

export default defineComponent({
    name: 'UserFeedbackResponseModal',
    mixins: [ModalButton],
    components: {
        ModalButton,
    },

    props: {
        label: {
            type: String,
        },
        userName: {
            type: String,
            required: true,
        },
        data: {
            type: Object,
            required: true,
        },
        topic: {
            type: String,
            required: true,
        },
    },

    setup() {
        const modalButtonRef = ref()
        return {
            ...globalFormState,
            modalButtonRef,
        }
    },

    computed: {
        defaultCase(): FormSpecification {
            return {
                title: {
                    label: '제목',
                    value: null,
                },
                body: {
                    label: '내용',
                    value: null,
                    type: InputType.TEXTAREA,
                },
                detailBody: {
                    label: '상세 내용',
                    value: null,
                    type: InputType.TEXTAREA,
                },
                link: {
                    label: '링크',
                    value: '/tab/home/notification',
                    disabled: true,
                },
                notificationType: {
                    label: '알림 유형',
                    value: null,
                    component: InputComponents.EnumSelect,
                    typeName: 'notification-type',
                    input: this.onSelectNotificationType,
                },
            }
        },

        allergyCase(): FormSpecification {
            return {
                title: {
                    value: `알러지 정보가 업데이트 되었어요.`,
                    label: '제목',
                },
                body: {
                    label: '내용',
                    value: `${this.userName} 님이 입력하신 ${this.safeTargetName}에 해당하는 알러지 선택지가 있어요. 마이페이지에서 등록하시겠어요?🙂`,
                    type: InputType.TEXTAREA,
                },
                detailBody: {
                    label: '상세 내용',
                    value: null,
                    type: InputType.TEXTAREA,
                },
                link: {
                    label: '링크',
                    value: '/tab/home/notification',
                    disabled: true,
                },
                notificationType: {
                    label: '알림 유형',
                    value: 'ALLERGY_REGISTRATION_RESOLVED',
                    component: InputComponents.EnumSelect,
                    typeName: 'notification-type',
                    description: () =>
                        (![
                            'ALLERGY_REGISTRATION_RESOLVED',
                            'ALLERGY_REGISTRATION_UNRESOLVED',
                        ].includes(this.formSpec.notificationType.value) &&
                            '알러지 알림 유형이 아닙니다. 주의하세요.') ||
                        '',
                    color: 'red',
                    input: this.onSelectNotificationType,
                },
                healthStatusCode: {
                    label: '알러지',
                    value: null,
                    component: InputComponents.AutoCompleteInput,
                    uri: '/api/health-status/allergy',
                    mode: SelectInputMode.DEFAULT,
                    select: (value: any) => {
                        this.formSpec.detailBody.value = value.detailBody
                        this.formSpec.notificationType.value =
                            value.notificationType
                    },
                },
            }
        },

        drugCase(): FormSpecification {
            return {
                title: {
                    label: '제목',
                    value: '의약물 정보가 업데이트 되었어요.',
                },
                body: {
                    label: '내용',
                    value: `${this.userName} 님이 입력하신 ${this.safeTargetName}에 해당하는 의약물 선택지가 있어요. 마이페이지에서 등록하시겠어요?🙂`,
                    type: InputType.TEXTAREA,
                },
                detailBody: {
                    label: '상세 내용',
                    value: null,
                    type: InputType.TEXTAREA,
                },
                link: {
                    label: '링크',
                    value: '/tab/home/notification',
                    disabled: true,
                },
                notificationType: {
                    label: '알림 유형',
                    value: 'DRUG_REGISTRATION_RESOLVED',
                    component: InputComponents.EnumSelect,
                    typeName: 'notification-type',
                    description: () =>
                        (![
                            'DRUG_REGISTRATION_RESOLVED',
                            'DRUG_REGISTRATION_UNRESOLVED',
                        ].includes(this.formSpec.notificationType.value) &&
                            '약물 알림 유형이 아닙니다. 주의하세요.') ||
                        '',
                    color: 'red',
                    input: this.onSelectNotificationType,
                },
                drugId: {
                    label: '약물',
                    value: null,
                    component: InputComponents.AutoCompleteInput,
                    uri: '/api/drug/name',
                    mode: SelectInputMode.DEFAULT,
                    select: (value: any) => {
                        this.formSpec.detailBody.value = value.detailBody
                        this.formSpec.notificationType.value =
                            value.notificationType
                    },
                },
            }
        },

        diseaseCase(): FormSpecification {
            return {
                title: {
                    label: '제목',
                    value: '질환 정보가 업데이트 되었어요.',
                },
                body: {
                    label: '내용',
                    value: `${this.userName} 님이 입력하신 ${this.safeTargetName}에 해당하는 기저질환 선택지가 있어요. 마이페이지에서 등록하시겠어요?🙂`,
                    type: InputType.TEXTAREA,
                },
                detailBody: {
                    label: '상세 내용',
                    value: null,
                    type: InputType.TEXTAREA,
                },
                link: {
                    label: '링크',
                    value: '/tab/home/notification',
                    disabled: true,
                },
                notificationType: {
                    label: '알림 유형',
                    value: 'DISEASE_REGISTRATION_RESOLVED',
                    component: InputComponents.EnumSelect,
                    typeName: 'notification-type',
                    description: () =>
                        (![
                            'DISEASE_REGISTRATION_RESOLVED',
                            'DISEASE_REGISTRATION_UNRESOLVED',
                        ].includes(this.formSpec.notificationType.value) &&
                            '질병 알림 유형이 아닙니다. 주의하세요.') ||
                        '',
                    color: 'red',
                    input: this.onSelectNotificationType,
                },
                healthStatusCode: {
                    label: '질병',
                    value: null,
                    component: InputComponents.AutoCompleteInput,
                    uri: '/api/health-status/disease',
                    mode: SelectInputMode.DEFAULT,
                    select: (value: any) => {
                        this.formSpec.detailBody.value = value.detailBody
                        this.formSpec.notificationType.value =
                            value.notificationType
                    },
                },
            }
        },

        productRegistrationCase(): FormSpecification {
            return {
                title: {
                    label: '제목',
                    value: '요청하신 제품을 업데이트했어요.',
                },
                body: {
                    label: '내용',
                    value: `${this.userName} 님이 요청하신 ${this.safeTargetName}에 대한 정보가 등록되었어요. 소중한 피드백 감사드려요💗`,
                    type: InputType.TEXTAREA,
                },
                detailBody: {
                    label: '상세 내용',
                    value: null,
                    type: InputType.TEXTAREA,
                },
                link: {
                    label: '링크',
                    value: '/tab/home/notification',
                    disabled: true,
                },
                notificationType: {
                    label: '알림 유형',
                    value: 'PRODUCT_REGISTRATION_RESOLVED',
                    component: InputComponents.EnumSelect,
                    typeName: 'notification-type',
                    description: () =>
                        (![
                            'PRODUCT_REGISTRATION_RESOLVED',
                            'PRODUCT_REGISTRATION_UNRESOLVED',
                            'PRODUCT_MODIFICATION_RESOLVED',
                            'PRODUCT_MODIFICATION_UNRESOLVED',
                        ].includes(this.formSpec.notificationType.value) &&
                            '제품 알림 유형이 아닙니다. 주의하세요.') ||
                        '',
                    color: 'red',
                    input: this.onSelectNotificationType,
                },
                productId: {
                    label: '제품ID',
                    value: this.data.productId,
                    component: InputComponents.ProductInput,
                    width: '300px',
                    select: (value: any) => {
                        const [productName, _] = value?.label?.split('#') || [
                            '',
                            null,
                        ]
                        this.formSpec.body.value = `${this.userName} 님이 요청하신 ${productName}에 대한 정보가 등록되었어요. 소중한 피드백 감사드려요💗`
                    },
                },
            }
        },

        foodRegistrationCase(): FormSpecification {
            return {
                title: {
                    label: '제목',
                    value: '요청하신 식품을 업데이트했어요.',
                },
                body: {
                    label: '내용',
                    value: `${this.userName} 님이 요청하신 ${this.safeTargetName}에 대한 정보가 등록되었어요. 소중한 피드백 감사드려요💗`,
                    type: InputType.TEXTAREA,
                },
                detailBody: {
                    label: '상세 내용',
                    value: null,
                    type: InputType.TEXTAREA,
                },
                link: {
                    label: '링크',
                    value: '/tab/home/notification',
                    disabled: true,
                },
                notificationType: {
                    label: '알림 유형',
                    value: 'FOOD_REGISTRATION_RESOLVED',
                    component: InputComponents.EnumSelect,
                    typeName: 'notification-type',
                    description: () =>
                        (![
                            'FOOD_REGISTRATION_RESOLVED',
                            'FOOD_REGISTRATION_UNRESOLVED',
                            'FOOD_MODIFICATION_RESOLVED',
                            'FOOD_MODIFICATION_UNRESOLVED',
                        ].includes(this.formSpec.notificationType.value) &&
                            '제품 알림 유형이 아닙니다. 주의하세요.') ||
                        '',
                    color: 'red',
                    input: this.onSelectNotificationType,
                },
                foodId: {
                    label: '식품ID',
                    value: this.data.foodId,
                    component: InputComponents.FoodInput,
                    width: '300px',
                    select: (value: any) => {
                        const [foodName, _] = value?.label?.split('#') || [
                            '',
                            null,
                        ]
                        this.formSpec.body.value = `${this.userName} 님이 요청하신 ${foodName}에 대한 정보가 등록되었어요. 소중한 피드백 감사드려요💗`
                    },
                },
            }
        },

        nutrientRegistrationCase(): FormSpecification {
            return {
                title: {
                    label: '제목',
                    value: `${this.userName} 님이 요청하신 ${this.safeTargetName}에 대한 정보가 등록되었어요. 소중한 피드백 감사드려요💗`,
                },
                body: {
                    label: '내용',
                    value: null,
                    type: InputType.TEXTAREA,
                },
                detailBody: {
                    label: '상세 내용',
                    value: null,
                    type: InputType.TEXTAREA,
                },
                link: {
                    label: '링크',
                    value: '/tab/home/notification',
                    disabled: true,
                },
                notificationType: {
                    label: '알림 유형',
                    value: 'NUTRIENT_REGISTRATION_RESOLVED',
                    component: InputComponents.EnumSelect,
                    typeName: 'notification-type',
                    description: () =>
                        (![
                            'NUTRIENT_REGISTRATION_RESOLVED',
                            'NUTRIENT_REGISTRATION_UNRESOLVED',
                            'NUTRIENT_MODIFICATION_RESOLVED',
                            'NUTRIENT_MODIFICATION_UNRESOLVED',
                        ].includes(this.formSpec.notificationType.value) &&
                            '영양소 알림 유형이 아닙니다. 주의하세요.') ||
                        '',
                    color: 'red',
                    input: this.onSelectNotificationType,
                },
                nutrientId: {
                    label: '영양소',
                    value: null,
                    component: InputComponents.NutrientInput,
                },
            }
        },

        safeTargetName() {
            return (
                this.data?.name ||
                this.data?.productName ||
                this.data?.nutrientName ||
                this.data?.foodName
            )
        },

        productModificationCase(): FormSpecification {
            return {
                title: {
                    label: '제목',
                    value: '요청하신 제품을 업데이트했어요.',
                },
                body: {
                    label: '내용',
                    value: `${this.userName} 님이 요청하신 ${this.safeTargetName}에 대한 정보가 수정되었어요. 소중한 피드백 감사드려요💗`,
                    type: InputType.TEXTAREA,
                },
                detailBody: {
                    label: '상세 내용',
                    value: null,
                    type: InputType.TEXTAREA,
                },
                link: {
                    label: '링크',
                    value: '/tab/home/notification',
                    disabled: true,
                },
                notificationType: {
                    label: '알림 유형',
                    value: 'PRODUCT_MODIFICATION_RESOLVED',
                    component: InputComponents.EnumSelect,
                    typeName: 'notification-type',
                    description: () =>
                        (![
                            'PRODUCT_REGISTRATION_RESOLVED',
                            'PRODUCT_REGISTRATION_UNRESOLVED',
                            'PRODUCT_MODIFICATION_RESOLVED',
                            'PRODUCT_MODIFICATION_UNRESOLVED',
                        ].includes(this.formSpec.notificationType.value) &&
                            '제품 알림 유형이 아닙니다. 주의하세요.') ||
                        '',
                    color: 'red',
                    input: this.onSelectNotificationType,
                },
                productId: {
                    label: '제품ID',
                    value: this.data.productId,
                    component: InputComponents.ProductInput,
                    uri: '/api/products/product-name',
                    mode: SelectInputMode.DEFAULT,
                    width: '300px',
                    select: (value: any) => {
                        const [productName, _] = value?.label?.split('#') || [
                            '',
                            null,
                        ]
                        this.formSpec.body.value = `${this.userName} 님이 요청하신 ${productName}에 대한 정보가 수정되었어요. 소중한 피드백 감사드려요💗`
                    },
                },
            }
        },

        nutrientModificationCase(): FormSpecification {
            return {
                title: {
                    label: '제목',
                    value: `${this.userName} 님이 요청하신 ${this.safeTargetName}에 대한 정보가 수정되었어요. 소중한 피드백 감사드려요💗`,
                },
                body: {
                    label: '내용',
                    value: null,
                    type: InputType.TEXTAREA,
                },
                detailBody: {
                    label: '상세 내용',
                    value: null,
                    type: InputType.TEXTAREA,
                },
                link: {
                    label: '링크',
                    value: '/tab/home/notification',
                    disabled: true,
                },
                notificationType: {
                    label: '알림 유형',
                    value: 'NUTRIENT_MODIFICATION_RESOLVED',
                    component: InputComponents.EnumSelect,
                    typeName: 'notification-type',
                    description: () =>
                        (![
                            'NUTRIENT_REGISTRATION_RESOLVED',
                            'NUTRIENT_REGISTRATION_UNRESOLVED',
                            'NUTRIENT_MODIFICATION_RESOLVED',
                            'NUTRIENT_MODIFICATION_UNRESOLVED',
                        ].includes(this.formSpec.notificationType.value) &&
                            '영양소 알림 유형이 아닙니다. 주의하세요.') ||
                        '',
                    color: 'red',
                    input: this.onSelectNotificationType,
                },
                nutrientId: {
                    label: '영양소',
                    value: null,
                    component: InputComponents.NutrientInput,
                },
            }
        },

        reviewCase(): FormSpecification {
            return {
                title: {
                    label: '제목',
                    value: `${this.userName} 님, ForMe 리뷰를 작성해주셔서 감사해요😊 비슷한 건강고민을 갖고 있는 다른 분들께 큰 도움이 될 거에요`,
                },
                body: {
                    label: '내용',
                    value: null,
                    type: InputType.TEXTAREA,
                },
                link: {
                    label: '링크',
                    value: '/tab/home/notification',
                    disabled: true,
                },
            }
        },

        reviewBlindCase(): FormSpecification {
            return {
                title: {
                    label: '제목',
                    value: `${this.userName} 님이 작성하신 ${this.safeTargetName}에 대한 리뷰가 블라인드 처리 되었습니다.`,
                },
                body: {
                    label: '내용',
                    value: null,
                    type: InputType.TEXTAREA,
                },
                link: {
                    label: '링크',
                    value: '/tab/home/notification',
                    disabled: true,
                },
            }
        },

        invalidProductTemplate() {
            return `${this.userName} 님이 요청하신 ${this.safeTargetName}에 문제가 있어 바로 업데이트가 어려워요😢`
        },

        invalidHealthStatusTemplate() {
            return `${this.userName} 님이 입력하신 [${this.safeTargetName}]에 대한 반영이 어려워요😥 `
        },

        preset(): InputSpecification {
            return {
                component: InputComponents.PresetInput,
                options: [
                    {
                        label: '초기화',
                        value: {
                            title: null,
                            body: null,
                            notificationType: null,
                        },
                    },
                    {
                        label: '제조사 함량 표시 안함',
                        value: {
                            title: this.notificationTypePreset[
                                'PRODUCT_MODIFICATION_UNRESOLVED'
                            ].title,
                            body: this.invalidProductTemplate,
                            detailBody:
                                '제품을 만든 제조사에서 영양성분 정보를 표시하고 있지 않아요. 탄수화물, 지방 등의 성분 외에 기능성을 인증 받은 영양성분의 함량이 있어야 분석이 가능해요. 혹시 함량표를 가지고 계시다면, support@pillyze.com 으로 보내주세요!',
                            notificationType: 'PRODUCT_MODIFICATION_UNRESOLVED',
                        },
                    },
                    {
                        label: '식물추출물',
                        value: {
                            title: this.notificationTypePreset[
                                'PRODUCT_MODIFICATION_UNRESOLVED'
                            ].title,
                            body: this.invalidProductTemplate,
                            detailBody:
                                `요청 주신 제품은 정확한 성분이 표시되지 않은 ‘추출물’ 정보만 있기 때문에 안내가 어려워요. 추출물에서 어떤 성분을 얼마나 농축했는지 알아야 정확한 안내가 가능한 점 양해 부탁드려요. 예시: 밀크씨슬 추출물은 ‘실리마린’으로 함량 표시가 있어야 해요`.trim(),
                            notificationType: (() => {
                                if (this.topic == 'NUTRIENT_CORRECTION') {
                                    return 'NUTRIENT_MODIFICATION_UNRESOLVED'
                                } else if (this.topic == 'PRODUCT_CORRECTION') {
                                    return 'PRODUCT_MODIFICATION_UNRESOLVED'
                                }
                                return 'PRODUCT_MODIFICATION_UNRESOLVED'
                            })(),
                        },
                    },
                    {
                        label: '제품 특정 불가 - 브랜드',
                        value: {
                            title: this.notificationTypePreset[
                                'PRODUCT_REGISTRATION_UNRESOLVED'
                            ].title,
                            body: this.invalidProductTemplate,
                            detailBody:
                                '브랜드사 이름으로 요청을 주셔서 어떤 제품의 등록을 원하시는지 알기가 어려워요. 대표 제품들을 등록했으니 살펴보시고, 혹시 원하는 제품이 없다면 다시 요청해주세요.',
                            notificationType: 'PRODUCT_REGISTRATION_UNRESOLVED',
                        },
                    },
                    {
                        label: '제품 특정 불가 - URL링크',
                        value: {
                            title: '요청하신 제품 등록에 어려움이 있어요.',
                            body: `${this.userName}님이 보내주신 ${this.safeTargetName}에 대한 정보로는 업데이트가 어려워요🥲`,
                            detailBody:
                                '함께 보내주신 URL 링크에서는 요청하신 제품을 찾기가 힘들어요. 제품 정보를 확인할 수 있는 URL 링크를 다시 보내주시면 최대한 빨리 등록해드릴게요🙂',
                            notificationType: 'PRODUCT_REGISTRATION_UNRESOLVED',
                        },
                    },
                    {
                        label: '제품 특정 불가 - 기타',
                        value: {
                            title: this.notificationTypePreset[
                                `PRODUCT_REGISTRATION_UNRESOLVED`
                            ].title,
                            body: this.invalidProductTemplate,
                            detailBody:
                                '어떤 제품을 요청하셨는지 특정하기가 어려워요. 원하시는 제품에 대해 조금 더 자세히 적어 요청해주시면 최대한 빨리 등록해드릴게요.',
                            notificationType: 'PRODUCT_REGISTRATION_UNRESOLVED',
                        },
                    },
                    {
                        label: '식품 특정 불가 - 일반음식, 외식',
                        value: {
                            title: this.notificationTypePreset[
                                'FOOD_REGISTRATION_UNRESOLVED'
                            ].title,
                            body: `${this.userName} 님이 요청하신 [${this.safeTargetName}]에 대한 정확한 정보 확인이 되지 않아 업데이트가 어려워요😢`,
                            notificationType: 'FOOD_REGISTRATION_UNRESOLVED',
                        },
                    },
                    {
                        label: '식품 특정 불가 - 가공식품',
                        value: {
                            title: this.notificationTypePreset[
                                'FOOD_REGISTRATION_UNRESOLVED'
                            ].title,
                            body: `${this.userName} 님이 요청하신 [${this.safeTargetName}]의 업데이트가 어려워요😢`,
                            detailBody:
                                '요청하신 식품의 영양 정보가 적힌 사진을 보내주시면 최대한 빨리 등록해 드릴게요.🙂',
                            notificationType: 'FOOD_REGISTRATION_UNRESOLVED',
                        },
                    },
                ],
            }
        },

        formRules(): Record<string, any> {
            return {
                title: [required],
                link: [required],
                notificationType: [required],
                productId: [
                    {
                        ...required,
                        required: [
                            'PRODUCT_REGISTRATION_RESOLVED',
                            'PRODUCT_MODIFICATION_RESOLVED',
                        ].includes(this.formSpec.notificationType?.value),
                    },
                ],
                foodId: [
                    {
                        ...required,
                        required: ['FOOD_REGISTRATION_RESOLVED'].includes(
                            this.formSpec.notificationType?.value
                        ),
                    },
                ],
                nutrientId: [
                    {
                        ...required,
                        required: [
                            'NUTRIENT_REGISTRATION_RESOLVED',
                            'NUTRIENT_MODIFICATION_RESOLVED',
                        ].includes(this.formSpec.notificationType?.value),
                    },
                ],
                healthStatusCode: [
                    {
                        ...required,
                        required: [
                            'ALLERGY_REGISTRATION_RESOLVED',
                            'DISEASE_REGISTRATION_RESOLVED',
                        ].includes(this.formSpec.notificationType?.value),
                    },
                ],
                drugId: [
                    {
                        ...required,
                        required: ['DRUG_REGISTRATION_RESOLVED'].includes(
                            this.formSpec.notificationType?.value
                        ),
                    },
                ],
            }
        },
        notificationTypePreset(): Record<string, any> {
            return {
                PRODUCT_REGISTRATION_RESOLVED: {
                    title: '요청하신 제품을 업데이트했어요.',
                },
                FOOD_REGISTRATION_RESOLVED: {
                    title: '요청하신 제품을 업데이트했어요.',
                },
                PRODUCT_REGISTRATION_UNRESOLVED: {
                    title: '등록 요청하신 제품에 문제가 있어요.',
                    body: this.invalidProductTemplate,
                },
                FOOD_REGISTRATION_UNRESOLVED: {
                    title: '등록 요청하신 식품 등록에 어려움이 있어요.',
                    body: '${this.userName} 님이 요청하신 ${this.safeTargetName}에 대한 정확한 정보 확인이 되지 않아 업데이트가 어려워요😢',
                },
                PRODUCT_MODIFICATION_RESOLVED: {
                    title: '요청하신 제품을 업데이트했어요.',
                },
                PRODUCT_MODIFICATION_UNRESOLVED: {
                    title: '수정 요청하신 제품에 문제가 있어요.',
                    body: this.invalidProductTemplate,
                },
                DRUG_REGISTRATION_RESOLVED: {
                    title: '의약물 정보가 업데이트 되었어요.',
                },
                DRUG_REGISTRATION_UNRESOLVED: {
                    title: '요청하신 의약물 정보에 문제가 있어요',
                    body: this.invalidHealthStatusTemplate,
                },
                ALLERGY_REGISTRATION_RESOLVED: {
                    title: '알러지 정보가 업데이트 되었어요.',
                },
                ALLERGY_REGISTRATION_UNRESOLVED: {
                    title: '요청하신 알러지 정보에 문제가 있어요.',
                    body: this.invalidHealthStatusTemplate,
                },
                DISEASE_REGISTRATION_RESOLVED: {
                    title: '질환 정보가 업데이트 되었어요.',
                },
                DISEASE_REGISTRATION_UNRESOLVED: {
                    title: '요청하신 질환 정보에 문제가 있어요',
                    body: this.invalidHealthStatusTemplate,
                },
            }
        },
    },

    methods: {
        onSelectNotificationType(value: any) {
            const { setFormValue } = globalFormState
            setFormValue('title', this.notificationTypePreset[value]?.title)
            if (this.notificationTypePreset[value]?.body != null) {
                setFormValue('body', this.notificationTypePreset[value]?.body)
            }
        },

        initActionSpec() {
            if (this.topic === 'ALLERGY') {
                this.overwriteFormSpec(this.allergyCase)
            } else if (this.topic === 'DRUG') {
                this.overwriteFormSpec(this.drugCase)
            } else if (this.topic === 'DISEASE') {
                this.overwriteFormSpec(this.diseaseCase)
            } else if (this.topic === 'PRODUCT_NOT_FOUND') {
                this.overwriteFormSpec(this.productRegistrationCase)
            } else if (this.topic === 'PRODUCT_CORRECTION') {
                this.overwriteFormSpec(this.productModificationCase)
            } else if (this.topic === 'NUTRIENT_CORRECTION') {
                this.overwriteFormSpec(this.nutrientModificationCase)
            } else if (this.topic === 'INGREDIENT_NOT_FOUND') {
                this.overwriteFormSpec(this.nutrientRegistrationCase)
            } else if (this.topic === 'ANALYSIS_IMPOSSIBLE_PRODUCTS') {
                this.overwriteFormSpec(this.productModificationCase)
            } else if (this.topic === 'FOOD_NOT_FOUND') {
                this.overwriteFormSpec(this.foodRegistrationCase)
            } else {
                this.overwriteFormSpec(this.defaultCase)
            }
        },

        initForm() {
            this.initActionSpec()
            this.overwriteFormSpec({
                preset: {
                    ...this.preset,
                    value: null,
                    input: (value: Record<string, any>) => {
                        const { body, title, detailBody } = value
                        const notificationType = value.notificationType
                        this.setFormValue('body', body)
                        this.setFormValue('detailBody', detailBody)
                        this.setFormValue('title', title)
                        this.setFormValue('notificationType', notificationType)

                        this.formSpec.productId &&
                            this.setFormValue('productId', null)
                        this.formSpec.healthStatusCode &&
                            this.setFormValue('healthStatusCode', null)
                        this.formSpec.drugId &&
                            this.setFormValue('drugId', null)
                    },
                    radio: true,
                },
                data: {
                    label: '유저 문의 data',
                    value: JSON.stringify(this.data),
                    type: InputType.TEXTAREA,
                    disabled: true,
                },
                ...this.formSpec,
            })
        },

        async onSubmit() {
            await this.post()
            this.modalButtonRef.toggleVisible()
        },

        async post() {
            this.modalButtonRef.toggleLoading()
            try {
                const response = await adminApi<ApiResponse<void>>(
                    `/api/user-feedback/reply`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            ...this.form,
                            data: JSON.parse(this.form?.data),
                            id: this.resourceId,
                        }),
                    }
                )

                this.modalButtonRef.toggleLoading()

                if (
                    response.success === undefined ||
                    response.success === false
                ) {
                    this.modalButtonRef.setErrorMessage(
                        response.errorMessage ||
                            '예외 발생 - 개발자에게 문의하세요'
                    )
                } else {
                    this.$emit('afterSubmit')
                    this.resetForm()
                }
            } catch (error) {
                console.error(error)
                throw error
            }
        },
    },
})
</script>

<template>
    <ResourceTable
        ref="eventUserTable"
        title="필라이즈 이벤트 참가자"
        uri="/api/v1/event-users"
        :filter-spec="filterSpec"
        :create-rules="rules"
        :update-rules="rules"
        :scroll="{ x: 1800, y: 650 }"
        :columns="columns"
        :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
        }"
        exportable
        load-on-mount
        method="POST"
    >
        <template #actions="{ uri, search }">
            <a-row>
                <a-col :span="24">
                    <h3>CRM 발송</h3>
                    <a-button @click="openCrmModal('sms')">문자</a-button>
                    <a-button @click="openCrmModal('bizm')">알림톡</a-button>
                </a-col>
                <a-col :span="24">
                    <h3>이벤트 인증 상태 값</h3>
                    <a-button
                        v-for="(item, index) in eventVerifications"
                        :key="index"
                        @click="() => updateVerification(item.value, search)"
                    >
                        {{ item.label }}
                    </a-button>
                </a-col>
                <a-col :span="24">
                    <h3>이벤트 관리</h3>
                    <a-button
                        @click="() => promoteEventUser('true', uri, search)"
                    >
                        당첨 처리
                    </a-button>
                    <a-button
                        @click="() => promoteEventUser('false', uri, search)"
                    >
                        당첨 취소
                    </a-button>
                    <bulk-action-modal
                        class="modal__button"
                        @after-submit="resetSelectedRows(search)"
                        :resource-ids="selectedRowKeys"
                        :uri="`${uri}/notify`"
                        :input-spec="notificationSpec"
                        :rules="notificationRules"
                        label="알림 센터 발송"
                        :disabled="!selected() || hasNotified()"
                    />
                    <bulk-action-modal
                        class="modal__button"
                        @after-submit="resetSelectedRows(search)"
                        :resource-ids="selectedRowKeys"
                        :uri="`${uri}/push`"
                        :input-spec="pushSpec"
                        :rules="notificationRules"
                        label="App push 발송"
                        :disabled="!selected()"
                    />
                </a-col>
            </a-row>
        </template>
        <template #customAction="{ record }">
            <a-image
                v-for="(url, index) in record.proofImageUrl"
                :src="url"
                :key="index"
                :preview="false"
                @click="showModal(record, url)"
            />
        </template>
    </ResourceTable>
    <event-user-verification-modal
        :is-show-modal-props="isShowModal"
        :image-url-props="imageUrl"
        :id-props="id"
        :event-id-props="eventId"
        :event-user-id-props="eventUserId"
        :event-user-name-props="eventUserName"
        :event-user-verification-status-props="eventUserVerificationStatus"
        :event-user-table-ref="eventUserTable"
        @modal:close="closeModal"
    />
    <send-crm-modal
        :is-send-one-props="false"
        :type-props="crmType"
        :label-props="crmLabel"
        :has-custom-button="true"
        :is-open-props="isCrmModalOpen"
        :event-user-list-props="crmEventUserList"
        :event-user-id-list-props="crmEventUserIdList"
        :event-user-props="crmEventUser"
        @modal:close="closeCrmModal"
        @modal:open="openCrmModal"
    />
</template>

<script lang="ts">
import {
    formatEnum,
    formatLocalDateTime,
    formatLocaleNumber,
    formatBoolean,
} from '@/util/formmater'
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent, ref, Ref } from 'vue'
import { required } from '@/util/input-validation'
import { stringify } from 'qs'
import { adminApi, ApiResponse, patchApi } from '@/fetchTemplate'
import BulkActionModal from '@/components/modal/BulkActionModal.vue'
import { InputComponents } from '@/components/InputMapper.vue'
import { useEnumTypeStore } from '@/store/enumType'
import { isEmpty } from 'lodash'
import EventUserVerificationModal from '@/components/modal/EventUserVerificationModal.vue'
import SendCrmModal from '@/components/modal/SendCrmModal.vue'

export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Event',
    components: {
        SendCrmModal,
        EventUserVerificationModal,
        ResourceTable,
        BulkActionModal,
    },
    setup() {
        const selectedRowKeys: Ref<Array<number>> = ref([])
        const selectedRows = ref<Array<Record<any, any>>>([])
        const selectedImageUrl = ref('')
        const loading = ref(false)
        const resetSelectedRows = (search: () => void) => {
            selectedRowKeys.value = []
            search()
        }
        const selected = () => {
            return selectedRowKeys.value.length > 0
        }
        const hasNotified = () => {
            const hasTrue = selectedRows.value.filter((row) => {
                if (row.notified === true) {
                    return true
                }
            })
            if (hasTrue.length > 0) {
                return true
            }
            return false
        }

        const modalLoading = ref(false)
        const imageUrl = ref()
        const id = ref()
        const eventId = ref()
        const eventUserId = ref()
        const eventUserName = ref()
        const eventUserVerificationStatus = ref()
        const isShowModal = ref(false)
        const eventUserTable = ref()
        const eventVerifications = ref([
            { label: '당첨', value: 'WINNER' },
            { label: '미당첨', value: 'NON_WINNER' },
            { label: '미구매자', value: 'PURCHASE_REQUIRED' },
            { label: '구매완료', value: 'PURCHASE_COMPLETE' },
            { label: '리뷰미작성', value: 'REVIEW_REQUIRED' },
            { label: '리뷰완료', value: 'REVIEW_COMPLETE' },
            { label: '구매보완요청', value: 'REQUEST_PURCHASE_COMPLETE' },
            { label: '리뷰보완요청', value: 'REQUEST_REVIEW_COMPLETE' },
            { label: '페이백완료', value: 'PAYBACK_COMPLETE' },
        ])

        const isCrmModalOpen = ref(false)
        const crmEventUserList = ref([] as any[])
        const crmEventUserIdList = ref([] as any[])
        const crmEventUser: any = ref(undefined)
        const crmType: any = ref(undefined)
        const crmLabel: any = ref(undefined)

        return {
            selectedRows,
            selectedRowKeys,
            selectedImageUrl,
            loading,
            imageUrl,
            id,
            eventId,
            eventUserId,
            eventUserName,
            isShowModal,
            modalLoading,
            eventUserTable,
            eventVerifications,
            eventUserVerificationStatus,
            resetSelectedRows,
            selected,
            hasNotified,
            isCrmModalOpen,
            crmEventUserList,
            crmEventUserIdList,
            crmEventUser,
            crmType,
            crmLabel,
        }
    },
    methods: {
        showModal(record: any, url: string) {
            this.isShowModal = true
            this.id = record.id
            this.eventId = record.eventId
            this.eventUserId = record.userId
            this.eventUserName = record.name
            this.eventUserVerificationStatus = record.verificationStatus
            this.imageUrl = url
        },
        closeModal() {
            this.isShowModal = false
        },
        onSelectChange(
            selectedRowKeys: Array<number>,
            selectedRows: Array<any>
        ) {
            this.selectedRowKeys = selectedRowKeys
            console.log(selectedRows)
            this.selectedRows = selectedRows
        },
        promoteEventUser(promote: string, uri: string, search: () => void) {
            const params: Record<string, any> = {
                ids: this.selectedRowKeys,
                promoted: promote,
            }
            const queryParams = stringify(params, { indices: false })
            this.updateEventUser(queryParams, uri + '/promote', search)
        },
        updateVerification(status: any, search: () => void) {
            this.updateVerificationStatus(status, search)
        },
        openCrmModal(type: string) {
            this.crmEventUser = this.selectedRows[0]
            this.crmEventUserIdList =
                this.selectedRows
                    .filter(
                        (eventUser: any) =>
                            eventUser.eventId === this.crmEventUser.eventId
                    )
                    .map((eventUser: any) => eventUser.id) || []
            this.crmEventUserList =
                this.selectedRows
                    .filter(
                        (eventUser: any) =>
                            eventUser.eventId === this.crmEventUser.eventId
                    )
                    .map(
                        (eventUser: any) =>
                            `${eventUser.name} (${eventUser.userId})`
                    ) || []
            this.isCrmModalOpen = true
            this.crmLabel = type === 'sms' ? '문자' : '알림톡'
            this.crmType = type === 'sms' ? 'SMS' : 'KAKAO_TALK'
        },
        closeCrmModal() {
            this.isCrmModalOpen = false
        },
        async updateVerificationStatus(status: any, search: () => void) {
            if (this.selectedRowKeys.length > 0 && this.loading === false) {
                this.loading = true
                try {
                    const url = `/api/v1/event-users/verification-status`
                    const payload = {
                        idList: this.selectedRowKeys,
                        status: status,
                    }
                    await patchApi(url, payload, true, '상태값 변경 실패')
                } catch (err) {
                    console.error(err)
                    throw err
                } finally {
                    this.loading = false
                    this.selectedRowKeys = []
                    search()
                }
            }
        },
        async updateEventUser(param: string, uri: string, search: () => void) {
            if (this.selectedRowKeys.length > 0 && this.loading === false) {
                this.loading = true
                try {
                    await adminApi<ApiResponse<void>>(`${uri}?${param}`, {
                        method: 'PATCH',
                    })
                } catch (err) {
                    console.error(err)
                    throw err
                } finally {
                    setTimeout(() => {
                        this.loading = false
                        this.selectedRowKeys = []
                        search()
                    }, 1000)
                }
            }
        },
    },
    data() {
        return {
            filterSpec: {
                eventId: {
                    label: '이벤트 ID',
                    value: null,
                    component: 'AInputNumber',
                },
                userId: {
                    label: '사용자 ID',
                    value: [],
                    component: 'AutoCompleteInput',
                    uri: '/api/user/id',
                    mode: 'multiple',
                },
                userIdCsv: {
                    label: '사용자 ID(,로 구분)',
                    value: null,
                    description:
                        '다른 사용자 ID와 동시 사용하지말것, 검증을 하지 않음으로 주의요망',
                },
                promoted: {
                    label: '당첨 여부',
                    value: null,
                    component: 'BooleanInput',
                    trueText: '당첨',
                    falseText: '미당첨',
                },
                feeStatus: {
                    label: '참가권 결제 여부',
                    value: null,
                    component: InputComponents.EnumSelect,
                    typeName: 'event-fee-status',
                },
                notified: {
                    label: '알림 여부',
                    value: null,
                    component: 'BooleanInput',
                    trueText: '알림',
                    falseText: '발송전',
                },
                verificationStatus: {
                    label: '인증 상태',
                    value: null,
                    component: InputComponents.EnumSelect,
                    typeName: 'event-user-verification-status',
                },
            },
            notificationSpec: {
                title: {
                    label: '제목',
                    value: null,
                    component: InputComponents.TemplateInput,
                },
                message: {
                    label: '메시지',
                    value: null,
                    component: InputComponents.TemplateInput,
                },
                body: {
                    label: '내용',
                    value: null,
                    component: InputComponents.TemplateInput,
                },
                detailBody: {
                    label: '상세내용',
                    value: null,
                    component: InputComponents.TemplateInput,
                },
                imageUrl: {
                    label: '이미지 URL',
                    value: null,
                    component: InputComponents.ImageInput,
                },
                detailLink: {
                    label: '상세링크',
                    value: null,
                },
            },
            pushSpec: {
                title: {
                    label: '제목',
                    value: null,
                    component: InputComponents.TemplateInput,
                },
                message: {
                    label: '메시지',
                    value: null,
                    component: InputComponents.TemplateInput,
                },
                body: {
                    label: '내용',
                    value: null,
                    component: InputComponents.TemplateInput,
                },
                imageUrl: {
                    label: '이미지 URL',
                    value: null,
                    component: InputComponents.ImageInput,
                },
                link: {
                    label: '링크',
                    value: null,
                },
            },
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    width: 50,
                    fixed: 'left',
                },
                {
                    title: '이벤트ID',
                    dataIndex: 'eventId',
                    width: 100,
                },
                {
                    title: '참가권 결제',
                    dataIndex: 'feeStatus',
                    customRender: formatEnum('event-fee-status'),
                    width: 100,
                },
                {
                    title: '계좌 연동',
                    dataIndex: 'bankAccountNumber',
                    customRender: (value: any) => {
                        if (value.value) {
                            return 'O'
                        }
                        return 'X'
                    },
                    width: 100,
                    align: 'center',
                },
                {
                    title: '당첨 여부',
                    dataIndex: 'promoted',
                    customRender: (value: any) => {
                        if (value.value) {
                            return 'O'
                        }
                        return 'X'
                    },
                    width: 100,
                    align: 'center',
                },
                {
                    title: '계좌 연동',
                    dataIndex: 'bankAccountNumber',
                    width: 70,
                    align: 'center',
                    customRender: (value: any) => {
                        if (isEmpty(value.value)) {
                            return 'X'
                        }
                        return 'O'
                    },
                },
                {
                    title: '사용자 ID',
                    dataIndex: 'userId',
                    width: 70,
                },
                {
                    title: '이름',
                    dataIndex: 'name',
                    width: 50,
                },
                {
                    title: '성별',
                    dataIndex: 'gender',
                    width: 100,
                    customRender: formatEnum('gender'),
                },
                {
                    title: '인증 상태 값',
                    dataIndex: 'verificationStatus',
                    width: 100,
                    customRender: formatEnum('event-user-verification-status'),
                },
                {
                    title: '나이',
                    dataIndex: 'age',
                    customRender: formatLocaleNumber,
                    align: 'right',
                    width: 100,
                },
                {
                    title: '이벤트 리뷰수',
                    dataIndex: 'eventReviewCount',
                    customRender: formatLocaleNumber,
                    align: 'right',
                    width: 100,
                },
                {
                    title: '총 리뷰수',
                    dataIndex: 'totalReviewCount',
                    customRender: formatLocaleNumber,
                    align: 'right',
                    width: 100,
                },
                {
                    title: '참여일자',
                    dataIndex: 'creationTime',
                    customRender: formatLocalDateTime,
                    width: 100,
                },
                {
                    title: '문자발송',
                    align: 'center',
                    width: 96,
                    type: 'send-crm-sms',
                },
                {
                    title: '알림톡발송',
                    type: 'send-crm-bizm',
                    align: 'center',
                    width: 96,
                },
                {
                    title: '증빙 이미지',
                    type: 'customAction',
                    width: 300,
                },
                {
                    title: '알림여부',
                    dataIndex: 'notified',
                    customRender: formatBoolean,
                    width: 60,
                },
            ],
            rules: {
                name: [required],
                title: [required],
                description: [required],
                serialNo: [required],
                eventUrl: [required],
                shareImageUrl: [required],
                eventType: [required],
                visible: [required],
                webVisible: [required],
                manager: [required],
                startAt: [required],
                endAt: [required],
            },
            notificationRules: {
                title: [required],
            },
            pushRules: {
                title: [required],
                link: [required],
            },
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums([
            'event-fee-status',
            'event-type',
            'gender',
        ])
    },
})
</script>

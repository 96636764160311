import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bulk_action_modal = _resolveComponent("bulk-action-modal")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_openBlock(), _createBlock(_component_ResourceTable, {
    title: "이벤트 사전알림 신청",
    uri: "/api/v1/event-user-alarms",
    "filter-spec": _ctx.filterSpec,
    "create-rules": _ctx.rules,
    "update-rules": _ctx.rules,
    scroll: { x: 1400, y: 650 },
    columns: _ctx.columns,
    "row-selection": {
            selectedRowKeys: _ctx.selectedRowKeys,
            onChange: _ctx.onSelectChange,
        },
    exportable: "",
    "load-on-mount": ""
  }, {
    actions: _withCtx(({ uri, search }) => [
      _createVNode(_component_bulk_action_modal, {
        class: "modal__button",
        onAfterSubmit: ($event: any) => (_ctx.resetSelectedRows(search)),
        "resource-ids": _ctx.selectedRowKeys,
        uri: `${uri}/notify`,
        "input-spec": _ctx.notificationSpec,
        rules: _ctx.notificationRules,
        label: "이벤트 알림 발송",
        disabled: !_ctx.selected()
      }, null, 8, ["onAfterSubmit", "resource-ids", "uri", "input-spec", "rules", "disabled"]),
      _createVNode(_component_bulk_action_modal, {
        class: "modal__button",
        onAfterSubmit: ($event: any) => (_ctx.resetSelectedRows(search)),
        "resource-ids": _ctx.selectedRowKeys,
        uri: `${uri}/push`,
        "input-spec": _ctx.pushSpec,
        rules: _ctx.notificationRules,
        label: "이벤트 알림 발송(알림만)",
        disabled: !_ctx.selected()
      }, null, 8, ["onAfterSubmit", "resource-ids", "uri", "input-spec", "rules", "disabled"])
    ]),
    customAction: _withCtx(({ record }) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.proofImageUrl, (url, index) => {
        return (_openBlock(), _createBlock(_component_a_image, {
          src: url,
          key: index
        }, null, 8, ["src"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["filter-spec", "create-rules", "update-rules", "columns", "row-selection"]))
}